// client/src/pages/ContactUs.js
import React, { useState } from 'react';



const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Replace with your backend API endpoint
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, subject, message }),
      });

      if (response.ok) {
        setFeedback('Thank you for your message! We will get back to you soon.');
        setIsError(false);
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
      } else {
        setFeedback('Something went wrong. Please try again.');
        setIsError(true);
      }
    } catch (error) {
      setFeedback('Something went wrong. Please try again.');
      setIsError(true);
    }
  };

  return (
    <div className="contact-us-container">
      <h1 className="contact-us-header">Contact Us</h1>
      <form className="contact-us-form" onSubmit={handleSubmit}>
        <label className="contact-us-label" htmlFor="name">Name:</label>
        <input
          className="contact-us-input"
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <label className="contact-us-label" htmlFor="email">Email:</label>
        <input
          className="contact-us-input"
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label className="contact-us-label" htmlFor="subject">Subject:</label>
        <input
          className="contact-us-input"
          id="subject"
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
        <label className="contact-us-label" htmlFor="message">Message:</label>
        <textarea
          className="contact-us-textarea"
          id="message"
          rows="6"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <button className="contact-us-button" type="submit">Send Message</button>
      </form>
      {feedback && <p className={`contact-us-message ${isError ? 'error' : 'success'}`}>{feedback}</p>}
    </div>
  );
};

export default ContactUs;
