const theme = {
  colors: {
    primary: '#47619a',  // Blue
    secondary: '#6c757d', // Gray
    success: '#28a745',  // Green
    danger: '#dc3545',   // Red
    warning: '#ffc107',  // Yellow
    info: '#17a2b8',     // Teal
    light: '#f8f9fa',    // Light Gray
    dark: '#343a40',     // Dark Gray
    white: '#ffffff',
    black: '#000000',
    copper: '#eaa15a'
  },
  fonts: {
    main: 'Roboto, sans-serif'
  }
};

export default theme;
