// client/src/services/reviewService.js
import axios from 'axios';
import authService from './authService';

const API_URL = 'https://ptesters.net/api/reviews/';

const createReview = (reviewData) => {
  return axios.post(API_URL, reviewData, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const getReviewsByUserId = (userId) => {
  return axios.get(API_URL + `user/${userId}`).then(response => response.data);
};

const getReviewsByJobId = (jobId) => {
  return axios.get(API_URL + `job/${jobId}`).then(response => response.data);
};

export default {
  createReview,
  getReviewsByUserId,
  getReviewsByJobId,
};
