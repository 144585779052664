import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fonts.main};
    background-color: ${({ theme }) => theme.colors.light};
    margin: 0;
    padding: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.colors.primary};
  }
  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  button {
    font-family: ${({ theme }) => theme.fonts.main};
  }
  input, select, textarea {
    font-family: ${({ theme }) => theme.fonts.main};
    padding: 10px;
    margin: 10px 0;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 5px;
  }
  form {
    display: flex;
    flex-direction: column;
  }
`;

export default GlobalStyle;
