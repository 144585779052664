// client/src/pages/Admin.js
import React, { useEffect, useState } from 'react';
import adminService from '../services/adminService';
import { useNavigate } from 'react-router-dom'; 
import Modal from '../components/AdminModal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../styles.css';



const Admin = () => {
  const [users, setUsers] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userPage, setUserPage] = useState(1);
  const [jobPage, setJobPage] = useState(1);
  const [userTotalPages, setUserTotalPages] = useState(1);
  const [jobTotalPages, setJobTotalPages] = useState(1);
  const itemsPerPage = 5;
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [reportPage, setReportPage] = useState(1); 
  const [reportTotalPages, setReportTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [notes, setNotes] = useState('');
  const [currentReportId, setCurrentReportId] = useState(null);
  const [currentJobId, setCurrentJobId] = useState(null);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersData, jobsData, reportsData] = await Promise.all([
          adminService.getUsers(),
          adminService.getJobs(),
          adminService.getReports(),  // Ensure this fetches reports correctly
        ]);
        setUsers(usersData);
        setJobs(jobsData);
        setReports(reportsData);
        setUserTotalPages(Math.ceil(usersData.length / itemsPerPage));
        setJobTotalPages(Math.ceil(jobsData.length / itemsPerPage));
        setReportTotalPages(Math.ceil(reportsData.length / itemsPerPage));  // Set total pages for reports
      } catch (error) {
        console.error('Failed to fetch admin data', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  const handleApproveReport = async (reportId, jobId) => {
    try {
      await adminService.approveReport(jobId, reportId);
      setReports(reports.filter(report => report._id !== reportId));
    } catch (error) {
      console.error('Failed to approve report', error);
    }
  };

  const handleRejectReport = (reportId, jobId) => {
    setCurrentReportId(reportId);
    setCurrentJobId(jobId);
    setShowModal(true);
  };

  // Function to handle saving notes
  const handleSaveNotes = async () => {
    try {
      await adminService.rejectReport(currentJobId, currentReportId, notes);
      setReports(reports.filter(report => report._id !== currentReportId));
      setShowModal(false);
      setNotes('');
    } catch (error) {
      console.error('Failed to reject report', error);
    }
  };

  const handleEditRole = async (userId, newRole) => {
    try {
      await adminService.updateUserRole(userId, newRole);
      setUsers(users.map(user => user._id === userId ? { ...user, role: newRole } : user));
    } catch (error) {
      console.error('Failed to update user role', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await adminService.deleteUser(userId);
      setUsers(users.filter(user => user._id !== userId));
    } catch (error) {
      console.error('Failed to delete user', error);
    }
  };

  const handleDeleteJob = async (jobId) => {
    try {
      await adminService.deleteJob(jobId);
      setJobs(jobs.filter(job => job._id !== jobId));
    } catch (error) {
      console.error('Failed to delete job', error);
    }
  };

  const handleViewJobDetails = (jobId) => {
    // Navigate to job details page
    console.log('View job details for job:', jobId);
    navigate(`/job/${jobId}`);  // Navigate to job details page
  };

  const handleUserPageChange = (newPage) => {
    setUserPage(newPage);
  };

  const handleJobPageChange = (newPage) => {
    setJobPage(newPage);
  };

  const handleReportPageChange = (newPage) => {
    setReportPage(newPage);
  };

  const handleDownloadReportFile = async (jobId, filename) => {
    try {
      const password = prompt('Enter the password to decrypt the file:');
      if (!password) {
        alert('Password is required to download the file');
        return;
      }
  
      const cleanFilename = filename.replace('uploads/', '');
      console.log(`Downloading report for job ID: ${jobId}, filename: ${cleanFilename}, with password: ${password}`);
      
      const response = await adminService.downloadFinalReportFile(jobId, cleanFilename, password);
      console.log('Download response:', response);
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', cleanFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Failed to download report file', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const paginatedUsers = (users && users.length > 0) ? users.slice((userPage - 1) * itemsPerPage, userPage * itemsPerPage) : [];
  const paginatedJobs = (jobs && jobs.length > 0) ? jobs.slice((jobPage - 1) * itemsPerPage, jobPage * itemsPerPage) : [];
  const paginatedReports = (reports && reports.length > 0) ? reports.slice((reportPage - 1) * itemsPerPage, reportPage * itemsPerPage) : [];

  return (
    <div className="admin-container">
      <h1>Admin Portal</h1>
      <div className="admin-list-container">
        <h2>Pending Reports for QA</h2>
        {paginatedReports.length > 0 ? (
          paginatedReports.map((report) => (
            <div className="admin-list-item" key={report._id}>
              <span>
                {report.title} - {report.finalReport.status}
              </span>
              <div>
                <button
                  className="admin-button"
                  onClick={() => {
                    if (report.finalReport && report.finalReport.files && report.finalReport.files.length > 0) {
                      handleDownloadReportFile(report._id, report.finalReport.files[0]);
                    }
                  }}
                >
                  Download Report
                </button>
                <button className="admin-button" onClick={() => handleApproveReport(report._id, report.jobId)}>
                  Approve
                </button>
                <button className="admin-button" onClick={() => handleRejectReport(report._id, report.jobId)}>
                  Reject
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No reports found.</p>
        )}
        <div className="pagination-container">
          <button onClick={() => handleReportPageChange(reportPage - 1)} disabled={reportPage === 1}>
            Previous
          </button>
          <button onClick={() => handleReportPageChange(reportPage + 1)} disabled={reportPage === reportTotalPages}>
            Next
          </button>
        </div>
      </div>
      <div className="admin-list-container">
        <h2>Users</h2>
        {paginatedUsers.length > 0 ? (
          paginatedUsers.map((user) => (
            <div className="admin-list-item" key={user._id}>
              <span>
                {user.name} - {user.email} ({user.role})
              </span>
              <div>
                <button className="admin-button" onClick={() => handleEditRole(user._id, user.role === 'client' ? 'tester' : 'client')}>
                  {user.role === 'client' ? 'Make Tester' : 'Make Client'}
                </button>
                <button className="admin-button" onClick={() => handleDeleteUser(user._id)}>
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No users found.</p>
        )}
        <div className="pagination-container">
          <button onClick={() => handleUserPageChange(userPage - 1)} disabled={userPage === 1}>
            Previous
          </button>
          <button onClick={() => handleUserPageChange(userPage + 1)} disabled={userPage === userTotalPages}>
            Next
          </button>
        </div>
      </div>
      <div className="admin-list-container">
        <h2>Jobs</h2>
        {paginatedJobs.length > 0 ? (
          paginatedJobs.map((job) => (
            <div className="admin-list-item" key={job._id}>
              <span>
                {job.title} - {job.status}
              </span>
              <div>
                <button className="admin-button" onClick={() => handleViewJobDetails(job._id)}>
                  View Details
                </button>
                <button className="admin-button" onClick={() => handleDeleteJob(job._id)}>
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No jobs found.</p>
        )}
        <div className="pagination-container">
          <button onClick={() => handleJobPageChange(jobPage - 1)} disabled={jobPage === 1}>
            Previous
          </button>
          <button onClick={() => handleJobPageChange(jobPage + 1)} disabled={jobPage === jobTotalPages}>
            Next
          </button>
        </div>
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)} onSave={handleSaveNotes} title="Enter Rejection Notes">
        <ReactQuill value={notes} onChange={setNotes} />
      </Modal>
    </div>
  );
};


export default Admin;
