// client/src/components/MessageForm.js
import React, { useState } from 'react';
import messageService from '../services/messageService';
import { FormContainer, InputField, Button } from '../components/StyledComponents';

const MessageForm = ({ receiverId, onMessageSent }) => {
  const [content, setContent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await messageService.sendMessage({ receiverId, content });
      setContent('');
      onMessageSent();
    } catch (error) {
      console.error('Failed to send message', error);
      alert('Failed to send message');
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <h1>Message</h1>
      <InputField
        as="textarea"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Type your message"
        required
      />
      <Button type="submit">Send</Button>
    </FormContainer>
  );
};

export default MessageForm;