// client/src/components/CreateCommentForm.js
import React, { useState } from 'react';
import styled from 'styled-components';
import commentService from '../services/commentService';
import { Button } from './StyledComponents';
import '../styles.css';



const CreateCommentForm = ({ postId, onCommentCreated }) => {
  const [content, setContent] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newComment = await commentService.createComment(postId, { content });
      onCommentCreated((prevComments) => [newComment, ...prevComments]);
      setContent('');
    } catch (err) {
      console.error('Failed to create comment', err);
    }
  };

  return (
    <form className="create-comment-form" onSubmit={handleSubmit}>
      <div>
        <label>Comment</label>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
          className="create-comment-textarea"
        />
      </div>
      <Button type="submit">Add Comment</Button>
    </form>
  );
};

export default CreateCommentForm;
