// client/src/pages/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import authService from '../services/authService';
import jobService from '../services/jobService';
import notificationService from '../services/notificationService';
import Profile from './Profile';
import styled from 'styled-components';
import MyCalendar from './Calendar';
import Job from './Job';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement } from 'chart.js';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UserIcon } from '../components/StyledComponents';
import SearchBar from '../components/SearchBar';
import userService from '../services/userService';
import '../styles.css';



ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement);




const DashboardContainer = styled.div`
  position: relative;
  margin-top: -1.1%;
  display: flex;
  border-top: 2px;
`;

const Sidebar = styled.div`
  position: relative;
  width: 250px;
  background-color: #47619a;
  color: white;
  min-height: 100vh;
  padding: 20px;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.3);
  z-index: 2;
  left: -2%;
  top: -2%;
  border-top: 1px solid #202020;
`;

const SidebarItem = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  &:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateX(5px);
  }
`;

const MainContent = styled.div`
  flex: 1;
  padding: 40px;
  background-color: #f4f7f9;
  min-height: 100vh;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

const Section = styled.div`
  margin-bottom: 40px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

const Card = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  margin-bottom: 20px;
`;

const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const CardContent = styled.p`
  font-size: 16px;
  color: #666;
`;

const Button = styled.button`
  background-color: #47619a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  text-decoration: none;
  display: inline-block;
  &:hover {
    background-color: #0056b3;
  }
`;

const NotificationItem = styled.div`
  background-color: #fff;
  padding: 15px;
  margin-bottom: 15px;
  border-left: 5px solid #47619a;
  border-radius: 5px;
  .notification-message {
    font-size: 16px;
    color: #333;
  }
  .notification-date {
    font-size: 14px;
    color: #666;
  }
  .dismiss-button {
    background: none;
    border: none;
    color: #47619a;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageItem = styled.div`
  margin: 0 5px;
  cursor: pointer;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
  &:hover {
    background-color: #47619a;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  &.active {
    font-weight: bold;
    background-color: #47619a;
    color: white;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  .slick-slide {
    display: flex !important; /* Important to ensure flex is applied */
    justify-content: center;
    align-items: center;
    height: 100% !important; /* Ensure the carousel items take up full height */
  }
`;

const JobCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  margin-bottom: 20px;
  height: 250px; // Ensures the card is square
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ClientInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ClientProfilePic = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 10px;
`;

const ClientProfilePicWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;


const ClientName = styled.span`
  font-size: 16px;
  color: #333;
  font-weight: bold;
`;

const JobTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const JobDescription = styled.p`
  font-size: 16px;
  color: #666;
`;

const JobGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
`;

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [dismissedNotifications, setDismissedNotifications] = useState([]);
  const [nonDismissedCount, setNonDismissedCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeSection, setActiveSection] = useState('dashboard');
  const [profileId, setProfileId] = useState(null);
  const [bidJobs, setBidJobs] = useState([]);
  const [openJobs, setOpenJobs] = useState([]);
  const [acceptedJobs, setAcceptedJobs] = useState([]);
  const [allJobs, setAllJobs] = useState([]); 
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [activeJobTab, setActiveJobTab] = useState('details');
  const [acceptedJobsPage, setAcceptedJobsPage] = useState(1);
  const [acceptedJobsTotalPages, setAcceptedJobsTotalPages] = useState(1);

  const [openJobsPage, setOpenJobsPage] = useState(1);
  const [openJobsTotalPages, setOpenJobsTotalPages] = useState(1);
  

  useEffect(() => {
    const currentUser = authService.getCurrentUser();

    if (currentUser) {
        setUser(currentUser);
        setProfileId(currentUser.id);

        notificationService.getNotifications().then((data) => {
            setNotifications(data);
            const storedDismissedNotifications = JSON.parse(localStorage.getItem('dismissedNotifications')) || [];
            setDismissedNotifications(storedDismissedNotifications);
            const nonDismissed = data.filter(notification => !storedDismissedNotifications.includes(notification._id));
            setNonDismissedCount(nonDismissed.length);
        });

        if (currentUser.role === 'client') {
            jobService.getClientJobs(currentUser.id).then((data) => {
                console.log('Client jobs:', data);
                setJobs(data);
                setTotalPages(Math.ceil(data.length / 10));
            });
        }
    }
}, []);

useEffect(() => {
  if (user && user.role === 'tester') {
      fetchOpenJobs(openJobsPage);
      fetchAcceptedJobs(acceptedJobsPage);
  }
}, [user, openJobsPage, acceptedJobsPage]);
  

  const handleAcceptedJobsPageChange = (page) => {
    setAcceptedJobsPage(page);
  };
  
  const handleOpenJobsPageChange = (page) => {
    setOpenJobsPage(page);
  };

  const fetchAcceptedJobs = async (page = 1) => {
    if (!user || !user.id) {
        console.log('User not found or not logged in');
        return;
    }

    try {
        const data = await jobService.getAllJobs();
        console.log('All jobs data:', data); // Debugging log to check the response

        if (!data || !Array.isArray(data)) {
            console.log('still no data');
            setAcceptedJobs([]);
            return;
        }

        const filteredJobs = data.filter(job => job.acceptedTesterId === user.id);
        setAcceptedJobsTotalPages(Math.ceil(filteredJobs.length / 10));

        const jobsWithClientData = filteredJobs.slice((page - 1) * 10, page * 10).map(async job => {
            try {
                const clientId = job.clientId._id || job.clientId;
                const clientData = await userService.getUserById(clientId); // Fetch user data by ID
                const URL = "https://ptesters.net/";
                const clientProfilePic = URL + clientData.profilePicture;
                const clientName = clientData.name ? clientData.name : 'Unknown Client';

                return {
                    ...job,
                    clientProfilePic,
                    clientName,
                };
            } catch (error) {
                console.error('Error fetching client data:', error);
                return {
                    ...job,
                    clientProfilePic: 'https://ptesters.net/assets/default.png',
                    clientName: 'Unknown Client',
                };
            }
        });

        const updatedJobs = await Promise.all(jobsWithClientData);
        console.log('Accepted jobs with client data:', updatedJobs); // Debugging log to check the processed jobs
        setAcceptedJobs(updatedJobs);
    } catch (error) {
        console.error('Error fetching accepted jobs:', error);
        setAcceptedJobs([]);
    }
};

  const renderJobCountByStatus = () => {
    const jobCountsByStatus = jobs.reduce((acc, job) => {
      acc[job.status] = (acc[job.status] || 0) + 1;
      return acc;
    }, {});
  
    const data = {
      labels: Object.keys(jobCountsByStatus),
      datasets: [
        {
          label: 'Job Count by Status',
          data: Object.values(jobCountsByStatus),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Job Count by Status',
        },
      },
    };
  
    return <Bar data={data} options={options} />;
  };

  const renderJobsPostedOverTime = () => {
    const jobsByDate = jobs.reduce((acc, job) => {
      const date = new Date(job.createdAt).toLocaleDateString();
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});
  
    const sortedDates = Object.keys(jobsByDate).sort((a, b) => new Date(a) - new Date(b));
  
    const data = {
      labels: sortedDates,
      datasets: [
        {
          label: 'Jobs Posted Over Time',
          data: sortedDates.map(date => jobsByDate[date]),
          fill: false,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Jobs Posted Over Time',
        },
      },
    };
  
    return <Line data={data} options={options} />;
  };

  const renderJobDistributionByType = () => {
    const jobCountsByType = jobs.reduce((acc, job) => {
      acc[job.type] = (acc[job.type] || 0) + 1;
      return acc;
    }, {});
  
    const data = {
      labels: Object.keys(jobCountsByType),
      datasets: [
        {
          label: 'Job Distribution by Type',
          data: Object.values(jobCountsByType),
          backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)'],
          borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Job Distribution by Type',
        },
      },
    };
  
    return <Doughnut data={data} options={options} />;
  };

  const handleJobSelect = (jobId) => {
    setSelectedJobId(jobId);
    setActiveSection('jobDetails');
  };

  const fetchJobs = (page) => {
    jobService.getAvailableJobs(page).then((data) => {
      setJobs(data.jobs);
      setTotalPages(data.totalPages);
    });
  };

  const fetchBidJobs = () => {
    jobService.getJobsTesterBidOn().then((data) => setBidJobs(data));
  };

  const fetchOpenJobs = (page = 1) => {
        jobService.getAvailableJobs(page, 10).then((data) => {
          const jobsWithClientData = data.jobs.map(async job => {
            try {
              const clientId = job.clientId._id || job.clientId;
              const clientData = await userService.getUserById(clientId); // Fetch user data by ID
              const URL = "https://ptesters.net/";
              const clientProfilePic = URL + clientData.profilePicture;
              const clientName = clientData.name ? clientData.name : 'Unknown Client';
      
              return {
                ...job,
                clientProfilePic,
                clientName,
              };
            } catch (error) {
              console.error('Error fetching client data:', error);
              return {
                ...job,
                clientProfilePic: 'https://ptesters.net/assets/default.png',
                clientName: 'Unknown Client',
              };
            }
          });
      
          Promise.all(jobsWithClientData).then(updatedJobs => {
            setOpenJobs(updatedJobs);
            setOpenJobsTotalPages(data.totalPages);
            setOpenJobsPage(page);
          });
        });
      };

  
  

  const handleDismissNotification = (notificationId) => {
    const updatedDismissedNotifications = [...dismissedNotifications, notificationId];
    setDismissedNotifications(updatedDismissedNotifications);
    localStorage.setItem('dismissedNotifications', JSON.stringify(updatedDismissedNotifications));
    setNonDismissedCount(nonDismissedCount - 1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleProfileClick = (id) => {
    console.log('Profile ID:', id); // Debug log
    setProfileId(id);
    setActiveSection('profile');
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'notifications':
        return (
          <Section>
            <SectionTitle>Your Notifications</SectionTitle>
            {notifications
              .filter(notification => !dismissedNotifications.includes(notification._id))
              .map(notification => (
                <NotificationItem key={notification._id}>
                  <div className="notification-message">{notification.message}</div>
                  <div className="notification-date">{new Date(notification.date).toLocaleString()}</div>
                  <button className="dismiss-button" onClick={() => handleDismissNotification(notification._id)}>Dismiss</button>
                </NotificationItem>
              ))}
            <Button as={Link} to="/notifications">View All Notifications</Button>
          </Section>
        );
        case 'jobStatistics':
      return (
        <Section>
          <SectionTitle>Job Statistics</SectionTitle>
          {renderJobCountByStatus()}
          {renderJobsPostedOverTime()}
          {renderJobDistributionByType()}
        </Section>
      );
      case 'jobs':
        const paginatedJobs = jobs.slice((currentPage - 1) * 10, currentPage * 10); // Slice jobs for pagination
        return (
          <Section>
            <SectionTitle>Your Posted Jobs</SectionTitle>
            <Button as={Link} to="/post-job">Post a New Job</Button>
            <p></p>
            <JobGrid>
              {paginatedJobs.map((job) => (
                <JobCard key={job._id}>
                  <CardTitle>{job.title}</CardTitle>
                  <CardContent>{job.description}</CardContent>
                  <Button as={Link} to={`/job/${job._id}`}>View Details</Button>
                </JobCard>
              ))}
            </JobGrid>
            <PaginationContainer>
              {Array.from({ length: totalPages }, (_, index) => (
                <PageItem
                  key={index}
                  className={currentPage === index + 1 ? 'active' : ''}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </PageItem>
              ))}
            </PaginationContainer>
          </Section>
        );
        
        case 'tester-jobs':
  return (
    <Section>
      <SectionTitle>Jobs in Progress</SectionTitle>
      <JobGrid>
        {acceptedJobs.length > 0 ? (
          acceptedJobs.map((job) => (
            <JobCard key={job._id}>
              <ClientInfo>
                <ClientProfilePicWrapper>
                  {job.clientProfilePic ? (
                    <ClientProfilePic 
                      src={job.clientProfilePic} 
                      alt="Client Profile Pic" 
                    />
                  ) : (
                    <UserIcon />
                  )}
                </ClientProfilePicWrapper>
                <ClientName>{job.clientName}</ClientName>
              </ClientInfo>
              <JobTitle>{job.title}</JobTitle>
              <JobDescription>{job.description}</JobDescription>
              <Button as={Link} to={`/job/${job._id}`}>View Details</Button>
            </JobCard>
          ))
        ) : (
          <CardContent>No accepted jobs yet.</CardContent>
        )}
      </JobGrid>
      <PaginationContainer>
        {Array.from({ length: acceptedJobsTotalPages }, (_, index) => (
          <PageItem
            key={index}
            className={acceptedJobsPage === index + 1 ? 'active' : ''}
            onClick={() => handleAcceptedJobsPageChange(index + 1)}
          >
            {index + 1}
          </PageItem>
        ))}
      </PaginationContainer>
      <SectionTitle>Available Jobs</SectionTitle>
      <JobGrid>
        {openJobs.length > 0 ? (
          openJobs.map((job) => (
            <JobCard key={job._id}>
              <ClientInfo>
                <ClientProfilePicWrapper>
                  {job.clientProfilePic ? (
                    <ClientProfilePic 
                      src={job.clientProfilePic} 
                      alt="Client Profile Pic"
                    />
                  ) : (
                    <UserIcon />
                  )}
                </ClientProfilePicWrapper>
                <ClientName>{job.clientName}</ClientName>
              </ClientInfo>
              <JobTitle>{job.title}</JobTitle>
              <JobDescription>{job.description}</JobDescription>
              <Button as={Link} to={`/job/${job._id}`}>View Details</Button>
            </JobCard>
          ))
        ) : (
          <CardContent>No open jobs available.</CardContent>
        )}
      </JobGrid>
      <PaginationContainer>
        {Array.from({ length: openJobsTotalPages }, (_, index) => (
          <PageItem
            key={index}
            className={openJobsPage === index + 1 ? 'active' : ''}
            onClick={() => handleOpenJobsPageChange(index + 1)}
          >
            {index + 1}
          </PageItem>
        ))}
      </PaginationContainer>
    </Section>
  );
            case 'profile':
              return (
                <Section>
                  <SectionTitle>Profile</SectionTitle>
                  {profileId && <Profile id={profileId} />}
                </Section>
              );
            default:
              const mostRecentJob = jobs[0]; // Assuming jobs are sorted by date
              return (
                <Section>
                  
                  <SearchBar />
                  <SectionTitle>Dashboard</SectionTitle>
                  <div className="dashboard-cards">
                    <Card>
                      <CardTitle>Your Notifications</CardTitle>
                      {notifications
                        .filter(notification => !dismissedNotifications.includes(notification._id))
                        .map(notification => (
                          <NotificationItem key={notification._id}>
                            <div className="notification-message">{notification.message}</div>
                            <div className="notification-date">{new Date(notification.date).toLocaleString()}</div>
                            <button className="dismiss-button" onClick={() => handleDismissNotification(notification._id)}>Dismiss</button>
                          </NotificationItem>
                        ))}
                      <Button as={Link} to="/notifications">View All Notifications</Button>
                    </Card>
                    <Card>
                      <CardTitle>Most Recent Job</CardTitle>
                      {mostRecentJob ? (
                        <>
                          <CardContent>Title: {mostRecentJob.title}</CardContent>
                          <CardContent>Description: {mostRecentJob.description}</CardContent>
                          <Button as={Link} to={`/job/${mostRecentJob._id}`}>View Details</Button>
                        </>
                      ) : (
                        <CardContent>No jobs posted yet.</CardContent>
                      )}
                    </Card>
                    <Card>
                      <CardTitle>Calendar</CardTitle>
                      <MyCalendar />
                    </Card>
                  </div>
                </Section>
              );
          }
        };
  

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard-container">
      <div className="sidebar">
        <div className="sidebar-item" onClick={() => setActiveSection('dashboard')}>Dashboard</div>
        <div className="sidebar-item" onClick={() => setActiveSection('notifications')}>Your Notifications</div>
        {user.role === 'client' && (
          <>
            <div className="sidebar-item" onClick={() => setActiveSection('jobs')}>Your Posted Jobs</div>
            <div className="sidebar-item" onClick={() => setActiveSection('jobStatistics')}>Job Statistics</div>
          </>
        )}
        {user.role === 'tester' && (
          <div className="sidebar-item" onClick={() => setActiveSection('tester-jobs')}>Jobs</div>
        )}
      </div>
      <div className="main-content">
        {renderSection()}
      </div>
    </div>
  );
};

export default Dashboard;
