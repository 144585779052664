// client/src/components/NotificationIcon.js
import React, { useEffect, useState } from 'react';
import notificationService from '../services/notificationService';
import styled from 'styled-components';
import '../styles.css';



const NotificationIcon = () => {
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const notifications = await notificationService.getNotifications(1, 10);
        const dismissedNotifications = JSON.parse(localStorage.getItem('dismissedNotifications')) || [];
        const activeNotifications = notifications.filter(notification => !dismissedNotifications.includes(notification._id));
        setNotificationCount(activeNotifications.length);
        console.log('Active notification count:', activeNotifications.length);
      } catch (error) {
        console.error('Failed to fetch notifications', error);
      }
    };

    fetchNotifications();
  }, []);

  return (
    <div className="icon-container">
      <i className="fas fa-bell"></i>
      {notificationCount > 0 && <div className="red-dot" />}
    </div>
  );
};


export default NotificationIcon;
