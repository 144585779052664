import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import postService from '../services/postService';
import categoryService from '../services/categoryService';
import { Button } from './StyledComponents';
import '../styles.css';

const CreatePostForm = ({ onPostCreated }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await categoryService.getCategories();
        setCategories(categoriesData);
        setSelectedCategory(categoriesData[0]?._id || '');
      } catch (error) {
        console.error('Failed to fetch categories', error);
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postService.createPost(title, content, selectedCategory);
      setTitle('');
      setContent('');
      onPostCreated();
    } catch (error) {
      console.error('Failed to create post', error);
    }
  };

  return (
    <form className="create-post-form" onSubmit={handleSubmit}>
      <div>
        <label>Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          className="create-post-input"
        />
      </div>
      <div>
        <label>Content:</label>
        <ReactQuill 
          value={content}
          onChange={setContent}
          theme="snow"
          required
          className="create-post-editor"
        />
      </div>
      <div>
        <label>Category:</label>
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="create-post-select"
        >
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <center><Button type="submit">Create Post</Button></center>
    </form>
  );
};

export default CreatePostForm;
