// client/src/pages/PostPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import postService from '../services/postService';
import commentService from '../services/commentService';
import CreateCommentForm from '../components/CreateCommentForm';
import '../styles.css';


const PostPage = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchComments = async (page) => {
    try {
      const { comments, totalPages } = await commentService.getComments(postId, page);
      setComments(comments);
      setTotalPages(totalPages);
    } catch (error) {
      setError('Failed to fetch comments');
      console.error('Failed to fetch comments', error);
    }
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postData = await postService.getPostById(postId);
        setPost(postData);
      } catch (error) {
        setError('Failed to fetch post');
        console.error('Failed to fetch post', error);
      }
    };

    fetchPost();
    fetchComments(page);
  }, [postId, page]);

  return (
    <div className="post-container">
      {error && <p>{error}</p>}
      {post ? (
        <>
          <h1 className="post-header">{post.title}</h1>
          <p>Posted by: {post.author.name} on {new Date(post.createdAt).toLocaleString()}</p>
          <div className="post-content">{post.content}</div>
          <p></p>
          <div className="comments-container">
            <h2>Comments</h2>
            {comments.map((comment) => (
              <div key={comment._id} className="comment">
                <small>Posted by: {comment.user.name} on {new Date(comment.createdAt).toLocaleString()}</small>
                <p>{comment.content}</p>
              </div>
            ))}
          </div>
          <div className="pagination">
            <button className="page-button" onClick={() => setPage(page - 1)} disabled={page === 1}>
              Previous
            </button>
            <button className="page-button" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
              Next
            </button>
          </div>
          <CreateCommentForm postId={postId} onCommentCreated={() => fetchComments(page)} />
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default PostPage;
