// client/src/components/MessageList.js
import React, { useEffect, useState } from 'react';
import messageService from '../services/messageService';
import authService from '../services/authService';

const MessageList = ({ userId }) => {
  const [messages, setMessages] = useState([]);
  const currentUser = authService.getCurrentUser();

  useEffect(() => {
    messageService.getMessages(userId).then((data) => setMessages(data));
  }, [userId]);

  return (
    <div>
      <h2>Messages</h2>
      {messages.map((message) => (
        <div key={message._id} className={message.senderId === currentUser.id ? 'sent' : 'received'}>
          <p>{message.content}</p>
          <small>{new Date(message.createdAt).toLocaleString()}</small>
        </div>
      ))}
    </div>
  );
};

export default MessageList;
