// client/src/pages/SearchResultsPage.js
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import searchService from '../services/searchService';
import '../styles.css';



const SearchResultsPage = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const searchResults = await searchService.searchPosts(query);
        setPosts(searchResults);
      } catch (error) {
        setError('Failed to fetch search results');
        console.error('Failed to fetch search results', error);
      }
    };

    if (query) {
      fetchSearchResults();
    }
  }, [query]);

  return (
    <div className="search-results-container">
      <h1>Search Results</h1>
      {error && <p>{error}</p>}
      {posts.map((post) => (
        <Link key={post._id} to={`/post/${post._id}`} className="post-link">
          <h2>{post.title}</h2>
          <p>{post.content}</p>
        </Link>
      ))}
    </div>
  );
};


export default SearchResultsPage;
