import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Job from './pages/Job';
import Profile from './pages/Profile';
import PostJob from './pages/PostJob';
import NotificationsPage from './pages/NotificationPage';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Disclaimer from './pages/Disclaimer';
import AccessibilityStatement from './pages/AccessibilityStatement';
import Header from './components/Header';
import { Container, Footer } from './components/StyledComponents';
import notificationService from './services/notificationService';
import authService from './services/authService';
import ErrorBoundary from './components/ErrorBoundary';
import setupAxiosInterceptors from './interceptors/axiosInterceptor';
import Admin from './pages/Admin';
import WebTestingStandardsPage from './pages/WebTestingStandardsPage';
import FAQ from './pages/FAQ';
import InfrastructureTestingStandards from './pages/InfrastructureTestingStandards';
import ContactUs from './pages/ContactUs';
import ForumPage from './pages/ForumPage';
import SearchResultsPage from './pages/SearchResultsPage';
import CategoryPosts from './pages/CategoryPosts';
import PostPage from './pages/PostPage';
import ForgotPassword from './pages/ForgotPassword';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [user, setUser] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const currentUser = authService.getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
    }

    const fetchNotificationCount = async () => {
      try {
        const count = await notificationService.getNotificationCount();
        setNotificationCount(count);
      } catch (error) {
        console.error('Failed to fetch notification count', error);
      }
    };

    fetchNotificationCount();
  }, []);

  const handleLogout = () => {
    authService.logout();
    setUser(null);
  };

  return (
    <Router>
      <Header user={user} onLogout={handleLogout} notificationCount={notificationCount} />
      <Container>
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login onLogin={setUser} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/job/:id" element={<Job />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/post-job" element={<PostJob />} />
            <Route path="/notifications" element={<NotificationsPage />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/accessibility-statement" element={<AccessibilityStatement />} />
            <Route path="/admin" element={user && user.role === 'admin' ? <Admin /> : <Navigate to="/login" />} />
            <Route path="/web-testing-standards" element={<WebTestingStandardsPage />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/infrastructure-testing-standards" element={<InfrastructureTestingStandards />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/forum" element={<ForumPage />} />
            <Route path="/search" element={<SearchResultsPage />} />
            <Route path="/category/:categoryId/posts" element={<CategoryPosts />} />
            <Route path="/post/:postId" element={<PostPage />} /> 
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </ErrorBoundary>
      </Container>
      <div className='footer'>
      <Footer>
        
        &copy; 2024 Pentesters Network  | <a href="/terms-of-service">Terms of Service</a> | <a href="/privacy-policy">Privacy Policy</a> | <a href="/disclaimer">Disclaimer</a> | <a href="/accessibility-statement">Accessibility Statement</a>
       
      </Footer>
      </div>
    </Router>
  );
}

export default App;
