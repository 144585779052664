// client/src/pages/ForgotPassword.js
import React, { useState } from 'react';
import authService from '../services/authService';
import { FormContainer, InputField, Button, LinkButton } from '../components/StyledComponents';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authService.forgotPassword(email);
      setMessage('Password reset instructions have been sent to your email.');
    } catch (error) {
      console.error('Failed to send reset instructions', error);
      setMessage('Failed to send reset instructions. Please try again.');
    }
  };

  return (
    <center><FormContainer>
      <h1>Forgot Password</h1>
      <form onSubmit={handleSubmit}>
        <InputField
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <center><Button type="submit">Send Reset Instructions</Button></center>
      </form>
      {message && <p>{message}</p>}
      <p></p>
      <LinkButton to="/login">Back to Login</LinkButton>
    </FormContainer></center>
  );
};

export default ForgotPassword;
