// client/src/services/jobService.js
import axios from 'axios';
import authService from './authService';

const API_URL = 'https://ptesters.net/api/jobs/';

const authHeader = () => ({
  'x-auth-token': authService.getToken()
});

const getClientJobs = (clientId) => {
  return axios.get(API_URL + `client/${clientId}`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const getAvailableJobs = (page = 1, limit = 10) => {
  return axios.get(API_URL, {
    params: { page, limit, sort: '-createdAt' },
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const getJobById = (id) => {
  return axios.get(API_URL + id, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const createJob = (jobData) => {
  return axios.post(`${API_URL}create`, jobData, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const deleteJob = (jobId) => {
  return axios.delete(`${API_URL}${jobId}`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};



const getFiles = (jobId) => {
  return axios.get(`${API_URL}${jobId}/files`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const deleteFile = (jobId, fileName) => {
  return axios.delete(API_URL + `${jobId}/files/${fileName}`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const sendMessage = (id, messageData) => {
  return axios.post(API_URL + id + '/messages', messageData, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const getMessages = (jobId) => {
  return axios.get(`${API_URL}${jobId}/messages`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const getNotifications = () => {
  return axios.get(`${API_URL}notifications`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const getFindings = (jobId) => {
  return axios.get(`${API_URL}${jobId}/findings`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const addFinding = (jobId, findingData) => {
  return axios.post(`${API_URL}${jobId}/findings`, findingData, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const saveFinding = (jobId, findingData) => {
  return axios.post(`${API_URL}${jobId}/findings`, findingData, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const deleteFinding = (jobId, findingId) => {
  console.log(`Sending delete request for job ID: ${jobId} and finding ID: ${findingId}`);
  return axios.delete(`${API_URL}${jobId}/findings/${findingId}`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => {
    console.log(`Delete request successful for finding ID: ${findingId}`, response.data);
    return response.data;
  }).catch(error => {
    console.error('Error in deleteFinding:', error.response ? error.response.data : error.message);
    throw error;
  });
};

const uploadScreenshots = (jobId, formData) => {
  return axios.post(`${API_URL}${jobId}/findings/upload`, formData, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const updateFinding = (jobId, findingId, findingData) => {
  return axios.put(`${API_URL}${jobId}/findings/${findingId}`, findingData, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const acceptBid = (jobId, bidId) => {
  return axios.post(API_URL + 'accept-bid', { jobId, bidId }, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const signJob = (jobId, signData) => {
  return axios.put(`${API_URL}${jobId}/sign`, signData, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const getOpenJobs = () => {
  return axios.get(API_URL + 'open-jobs', {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const getJobsTesterBidOn = () => {
  console.log('Request to getJobsTesterBidOn');
  return axios.get(API_URL + 'bid-jobs', {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => {
    console.log('Response from getJobsTesterBidOn:', response.data); // Debugging log
    return response.data;
  }).catch(err => {
    console.error('Error in getJobsTesterBidOn:', err);
    throw err;
  });
};

const getAcceptedJobsForTester = (testerId) => {
  console.log('Request to getAcceptedJobsForTester for testerId:', testerId);
  return axios.get(API_URL + `accepted-jobs/${testerId}`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => {
    console.log('Response from getAcceptedJobsForTester:', response.data); // Debugging log
    return response.data;
  }).catch(err => {
    console.error('Error in getAcceptedJobsForTester:', err);
    throw err;
  });
};

const getAllJobs = () => {
  console.log('Request to getAllJobs');
  return axios.get(API_URL + 'all-jobs', {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => {
    console.log('Response from getAllJobs:', response.data); // Debugging log
    return response.data;
  }).catch(err => {
    console.error('Error in getAllJobs:', err);
    throw err;
  });
};

const uploadFile = (jobId, file, password) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('password', password);

  return axios.post(`${API_URL}${jobId}/files`, formData, {
    headers: {
      'x-auth-token': authService.getToken(),
      'Content-Type': 'multipart/form-data'
    }
  }).then(response => response.data);
};

const submitFinalReport = (jobId, reportData, password) => {
  const formData = new FormData();
  formData.append('content', reportData.content);
  formData.append('password', password);  // Ensure the password is included
  console.log('Submitting report file');
  if (reportData.files && Array.isArray(reportData.files)) {
    reportData.files.forEach(file => {
      formData.append('files', file);
    });
    console.log('Trying to append the report file: ', formData)
  }

  return axios.post(`${API_URL}${jobId}/final-report`, formData, {
    headers: {
      'x-auth-token': authService.getToken(),
      'Content-Type': 'multipart/form-data'
    }
  }).then(response => response.data);
};

const getFinalReportFiles = (jobId) => {
  return axios.get(`${API_URL}${jobId}/final-report/files`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const downloadFinalReportFile = (jobId, fileName, password) => {
  return axios.post(`${API_URL}${jobId}/final-report/files/${fileName}`, { password }, {
    headers: {
      'x-auth-token': authService.getToken()
    },
    responseType: 'blob'
  }).then(response => {
    return response;
  });
};

const deleteFinalReportFile = (jobId, fileName) => {
  return axios.delete(`${API_URL}${jobId}/final-report/files/${fileName}`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const downloadFile = (jobId, fileName, password) => {
  return axios.post(`${API_URL}${jobId}/files/${fileName}`, { password }, {
    headers: {
      'x-auth-token': authService.getToken()
    },
    responseType: 'blob' // Ensure the response is handled as a binary large object (blob)
  }).then(response => {
    return response;
  });
};

const makePayment = async (jobId, type) => {
  const response = await axios.post(`${API_URL}${jobId}/${type}-payment`, {}, { headers: authHeader() });
  return response.data;
};

const getPaymentDetails = async (jobId) => {
  const response = await axios.get(`${API_URL}${jobId}/payments`, { headers: authHeader() });
  return response.data;
};

const createStripePayment = (jobId, paymentType, paymentMethodId) => {
  return axios.post(`${API_URL}create-stripe-payment`, { jobId, paymentType, paymentMethodId }, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const saveChecklistProgress = (jobId, checklistItems) => {
  return axios.post(`${API_URL}${jobId}/checklist`, checklistItems, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const getChecklistProgress = (jobId) => {
  return axios.get(`${API_URL}${jobId}/checklist`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};



export default {
  getClientJobs,
  getAvailableJobs,
  getJobById,
  createJob,
  uploadFile,
  getFiles,
  deleteFile,
  sendMessage,
  getMessages,
  getNotifications,
  getFindings,
  addFinding,
  saveFinding,
  deleteFinding,
  uploadScreenshots,
  updateFinding,
  deleteJob,
  acceptBid,
  signJob,
  getJobsTesterBidOn,
  getOpenJobs,
  getAcceptedJobsForTester,
  getAllJobs,
  submitFinalReport,
  downloadFile,
  makePayment,
  getPaymentDetails,
  createStripePayment,
  saveChecklistProgress,
  getChecklistProgress,
  getFinalReportFiles,
  downloadFinalReportFile,
  deleteFinalReportFile,
};