import axios from 'axios';
import authService from './authService';

const API_URL = 'https://ptesters.net/api/calendar/';

const getCalendarEvents = async () => {
  return axios.get(API_URL, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const deleteCalendarEvent = async (eventId) => {
    return axios.delete(`${API_URL}${eventId}`, {
      headers: { 'x-auth-token': authService.getToken() }
    }).then(response => response.data);
  };
  
  export default {
    getCalendarEvents,
    deleteCalendarEvent
  };