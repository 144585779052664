import React, { useEffect, useState } from 'react';
import reviewService from '../services/reviewService';
import styled from 'styled-components';
import StarRating from './StarRating'; // Import the StarRating component
import '../styles.css';



const ReviewList = ({ jobId, revieweeId }) => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage, setReviewsPerPage] = useState(5);

  useEffect(() => {
    if (jobId) {
      reviewService.getReviewsByJobId(jobId).then((data) => {
        setReviews(data);
      }).catch(error => {
        console.error('Failed to fetch reviews for job:', error);
      });
    } else if (revieweeId) {
      reviewService.getReviewsByUserId(revieweeId).then((data) => {
        setReviews(data);
      }).catch(error => {
        console.error('Failed to fetch reviews for user:', error);
      });
    }
  }, [jobId, revieweeId]);

  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handlePerPageChange = (e) => {
    setReviewsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page when per page changes
  };

  return (
    <div className="review-container">
      <h2>Reviews</h2>
      <div>
        <label htmlFor="perPage">Reviews per page: </label>
        <select id="perPage" value={reviewsPerPage} onChange={handlePerPageChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
        </select>
      </div>
      {currentReviews.length === 0 ? (
        <p>No reviews yet.</p>
      ) : (
        currentReviews.map((review) => (
          <div className="review-item" key={review._id}>
            <p className="reviewer-name">Reviewer: {review.reviewerId.name}</p>
            <div className="rating">
              Rating: <StarRating rating={review.rating} /> {/* Use StarRating component */}
            </div>
            <p className="comment">Comment: {review.comment}</p>
          </div>
        ))
      )}
      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <button
          disabled={indexOfLastReview >= reviews.length}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ReviewList;
