import React from 'react';
import { createRoot } from 'react-dom/client'; // Updated import for createRoot
import App from './App';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './globalStyles';
import theme from './Theme';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // Create the root

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);