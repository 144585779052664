import React from 'react';
import '../styles.css';

const PrivacyPolicy = () => (
  <div className="privacy-policy-container">
    <h1>Privacy Policy</h1>
    <p>Last updated: [Date]</p>

    <h2>1. Introduction</h2>
    <p>Welcome to Pentesters Network. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.</p>

    <h2>2. Important Information and Who We Are</h2>
    <p>Controller: Pentesters Network is the controller and responsible for your personal data.</p>
    <p>Contact Details: If you have any questions about this privacy policy or our privacy practices, please contact us at [Your Contact Information].</p>

    <h2>3. The Data We Collect About You</h2>
    <p>We may collect, use, store, and transfer different kinds of personal data about you, including:
      <ul>
        <li>Identity Data: name, username or similar identifier</li>
        <li>Contact Data: email address</li>
        <li>Profile Data: your username and password, profile picture, preferences, feedback, and survey responses</li>
        <li>Usage Data: information about how you use our website, products, and services</li>
        <li>Marketing and Communications Data: your preferences in receiving marketing from us and your communication preferences</li>
      </ul>
    </p>

    <h2>4. How We Collect Your Personal Data</h2>
    <p>We use different methods to collect data from and about you, including:
      <ul>
        <li>Direct interactions: You may give us your identity and contact data by filling in forms or by corresponding with us by post, phone, email, or otherwise.</li>
        <li>Automated technologies or interactions: As you interact with our website, we may automatically collect technical data about your equipment, browsing actions, and patterns.</li>
      </ul>
    </p>

    <h2>5. How We Use Your Personal Data</h2>
    <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
      <ul>
        <li>To manage your account and provide you with our services</li>
        <li>To manage our relationship with you, including notifying you about changes to our terms or privacy policy</li>
        <li>To administer and protect our business and this website</li>
        <li>To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</li>
      </ul>
    </p>

    <h2>6. Disclosure of Your Personal Data</h2>
    <p>We may share your personal data with third parties for the purposes set out in section 5 above. We require all third parties to respect the security of your personal data and to treat it in accordance with the law.</p>

    <h2>7. Data Security</h2>
    <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed.</p>

    <h2>8. Data Retention</h2>
    <p>We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

    <h2>9. Your Legal Rights</h2>
    <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to access, correct, or erase your personal data, object to or restrict processing, and withdraw consent.</p>

    <h2>10. Changes to the Privacy Policy</h2>
    <p>We keep our privacy policy under regular review. This version was last updated on [Date]. We may update this policy from time to time by posting a new version on our website.</p>

    <h2>11. Contact Us</h2>
    <p>If you have any questions or concerns about this privacy policy or our data practices, please contact us at [Your Contact Information].</p>
  </div>
);

export default PrivacyPolicy;
