// client/src/pages/WebTestingStandardsPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';


const WebTestingStandardsPage = () => {
  return (
    <div className="standards-container">
      <h1>Web Testing Standards</h1>
      <h2>What is the scope of testing provided by the independent testers?</h2>
      <p>
        The independent testers are required to cover a comprehensive list of security concepts and vulnerabilities as outlined by the PortSwigger Academy. This includes, but is not limited to:
      </p>
      <ul>
        <li>SQL Injection</li>
        <li>Cross-Site Scripting (XSS)</li>
        <li>Cross-Site Request Forgery (CSRF)</li>
        <li>XML External Entity (XXE) attacks</li>
        <li>Insecure Deserialization</li>
        <li>Command Injection</li>
        <li>Directory Traversal</li>
        <li>File Upload Vulnerabilities</li>
        <li>Authentication and Authorization Vulnerabilities</li>
        <li>Server-Side Request Forgery (SSRF)</li>
        <li>Business Logic Vulnerabilities</li>
        <li>WebSockets Security</li>
        <li>Cryptographic Issues</li>
        <li>Clickjacking</li>
        <li>Content Security Policy (CSP) implementation</li>
        <li>Security Misconfigurations</li>
        <li>API Security</li>
        <li>Subdomain Takeover</li>
        <li>Server-Side Template Injection (SSTI)</li>
        <li>https Host Header Attacks</li>
        <li>https Request Smuggling</li>
        <li>CORS Misconfiguration</li>
        <li>https Parameter Pollution</li>
        <li>Sensitive Data Exposure</li>
        <li>Broken Access Control</li>
        <li>Insufficient Logging and Monitoring</li>
      </ul>

      <h2>How do the testers ensure that all these concepts are covered during testing?</h2>
      <p>
        Testers follow a detailed checklist based on the PortSwigger Academy's curriculum. They utilize tools like Burp Suite to systematically test for each type of vulnerability. Testers provide detailed reports of their findings, including the methods used to identify and exploit each vulnerability.
      </p>

      <h2>Can clients request specific areas of focus during the testing process?</h2>
      <p>
        Yes, clients can specify particular areas of concern or focus in their testing requirements. Testers will prioritize these areas while still ensuring that the entire scope of the PortSwigger Academy’s checklist is covered.
      </p>

      <h2>What kind of reports do testers provide after completing their testing?</h2>
      <p>
        Testers provide comprehensive reports that detail all identified vulnerabilities, the methods used to find and exploit them, the potential impact of each vulnerability, and recommendations for remediation. These reports are designed to help clients understand the security posture of their applications and take appropriate action.
      </p>

      <h2>How often should we have our applications tested?</h2>
      <p>
        It is recommended to conduct security testing regularly, especially after any significant changes or updates to the application. At a minimum, annual testing should be conducted, but more frequent testing may be necessary for applications that handle sensitive data or are subject to compliance requirements.
      </p>

      <h2>Are the testers certified or trained in using specific tools or methodologies?</h2>
      <p>
        While all testers have varying credentials, which can be viewed on their profile pages, they are generally proficient in using Burp Suite and follow methodologies outlined by industry standards such as OWASP.
      </p>
      <p>
        <Link to="/testers">View tester profiles and credentials</Link>
      </p>

      <h2>What steps are taken if a critical vulnerability is found during testing?</h2>
      <p>
        If a critical vulnerability is found, testers will immediately notify the client and provide a detailed analysis of the issue, including potential risks and recommended remediation steps. The client can then take swift action to address the vulnerability and mitigate any potential threats.
      </p>

      <h2>Can we see examples of previous testing reports or client testimonials?</h2>
      <p>
        While specific details of previous client engagements are confidential, we can provide anonymized examples of testing reports and aggregate feedback from previous clients to illustrate the thoroughness and effectiveness of our testing process.
      </p>

      <h2>How are the testers vetted to ensure they are qualified and trustworthy?</h2>
      <p>
        Testers undergo a rigorous vetting process that includes background checks, verification of certifications, and practical evaluations of their skills and knowledge. This ensures that only highly qualified and trustworthy professionals are entrusted with the security testing of your applications.
      </p>

      <h2>What tools and methodologies do testers use for security testing?</h2>
      <p>
        Testers primarily use Burp Suite, along with other industry-standard tools and methodologies outlined by OWASP. These tools and methods are continuously updated to reflect the latest threats and best practices in web application security.
      </p>

      <h2>Is there ongoing support after the testing is completed?</h2>
      <p>
        Yes, we provide ongoing support to help clients understand the findings and implement the recommended remediation steps. Testers are available for follow-up consultations and can conduct retesting to ensure that vulnerabilities have been effectively addressed.
      </p>
    </div>
  );
};

export default WebTestingStandardsPage;
