// client/src/pages/CategoryPosts.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import postService from '../services/postService';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import categoryService from '../services/categoryService';
import Modal from '../components/Modal';
import { Button } from '../components/StyledComponents';
import CreatePostForm from '../components/CreatePostForm';



const CategoryPosts = () => {
  const { categoryId } = useParams();
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [category, setCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchPosts = async (page) => {
    try {
      const { posts, totalPages } = await postService.getPostsByCategory(categoryId, page );
      setPosts(posts);
      setTotalPages(totalPages);
    } catch (error) {
      setError('Failed to fetch posts');
      console.error('Failed to fetch posts', error);
    }
  };

  const fetchCategory = async () => {
    try {
      const categoryData = await categoryService.getCategoryById(categoryId);
      setCategory(categoryData);
      console.log('Category Data: ', categoryData);
    } catch (error) {
      setError('Failed to fetch category');
      console.error('Failed to fetch category', error);
    }
  };

  useEffect(() => {
    fetchPosts(page);
    fetchCategory();
  }, [categoryId, page]);

  const handleCreatePost = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div className="category-posts-container">
      <h1>{category ? `${category.name} Posts` : 'Posts'}</h1>
      {error && <p>{error}</p>}
  
      <Button onClick={handleCreatePost}>Create Post</Button>
      <p></p>
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <CreatePostForm onPostCreated={handleCloseModal} />
        </Modal>
      )}
      {posts.map((post) => (
        <Link className="post-link" key={post._id} to={`/post/${post._id}`}>
          <div>{post.title}</div>
          <div className="post-info">
            <span>Posted by: {post.author.name}</span>
            <span>{new Date(post.createdAt).toLocaleString()}</span>
          </div>
        </Link>
      ))}
      <div className="pagination">
        <button className="page-button" onClick={() => setPage(page - 1)} disabled={page === 1}>
          Previous
        </button>
        <button className="page-button" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default CategoryPosts;