// client/src/services/bidService.js
import axios from 'axios';
import authService from './authService';

const API_URL = 'https://ptesters.net/api/bids/';

const placeBid = (jobId, bidData) => {
  return axios.post(`${API_URL}${jobId}`, bidData, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => {
    console.log('Response from placeBid:', response.data); // Debugging log
    return response.data;
  }).catch(error => {
    console.error('Error placing bid:', error.response ? error.response.data : error.message);
    throw error;
  });
};

const getBidsForJob = (jobId) => {
  return axios.get(`${API_URL}${jobId}`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => {
    console.log('Bids for job:', jobId, response.data); // Debugging log
    return response.data;
  }).catch(error => {
    console.error('Error fetching bids for job:', error.response ? error.response.data : error.message);
    throw error;
  });
};


const acceptBid = async (bidId) => {
  try {
    console.log(`Sending request to accept bid with ID: ${bidId}`);
    const response = await axios.post(`${API_URL}accept/${bidId}`, {}, {
      headers: { 'x-auth-token': authService.getToken() }
    });
    console.log('Response from server:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error accepting bid:', error.response ? error.response.data : error.message);
    throw error;
  }
};


const rejectBid = (bidId) => {
  return axios.post(`${API_URL}reject/${bidId}`, {}, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

export default {
  placeBid,
  getBidsForJob,
  acceptBid,
  rejectBid
};
