// client/src/pages/Register.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import { Container, Button, LinkButton, FormContainer, InputField, SelectField } from '../components/StyledComponents';
import '../styles.css';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('client');
  const [certifications, setCertifications] = useState('');
  const [experience, setExperience] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authService.register(name, email, password, role, certifications, experience, linkedin);
      navigate('/dashboard');
    } catch (error) {
      console.error('Failed to register', error);
      alert('Registration failed. Please try again.');
    }
  };

  return (
    <center>
      <div className="form-container">
        <h1>Register</h1>
        <form onSubmit={handleSubmit}>
          <input
            className="input-field"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            required
          />
          <input
            className="input-field"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            className="input-field"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <select
            className="select-field"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="client">Client</option>
            <option value="tester">Tester</option>
          </select>
          {role === 'tester' && (
            <>
              <input
                className="input-field"
                type="text"
                value={certifications}
                onChange={(e) => setCertifications(e.target.value)}
                placeholder="Certifications"
              />
              <input
                className="input-field"
                type="text"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                placeholder="Professional Experience"
              />
              <input
                className="input-field"
                type="url"
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
                placeholder="LinkedIn Profile"
              />
            </>
          )}
          <center><Button type="submit">Register</Button></center>
        </form>
        <p></p>
        <LinkButton to="/login">Already have an account? Login</LinkButton>
      </div>
    </center>
  );
};


export default Register;
