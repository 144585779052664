import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import '../styles.css';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await authService.login(email, password);
      onLogin(user.user); // Pass the user data to the onLogin prop
      navigate('/dashboard');
    } catch (error) {
      console.error('Failed to login', error);
      alert('Login failed. Please check your credentials and try again.');
    }
  };

  return (
    <center>
      <div className="login-form-container">
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <input
            className="input-field"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            className="input-field"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <div className="login-checkbox-container">
            <label>
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              Remember Me
            </label>
            <a className="login-forgot-password-link" href="/forgot-password">Forgot Password?</a>
          </div>
          <center><button className="button" type="submit">Login</button></center>
        </form>
        <p></p>
        <a className="link-button" href="/register">Don't have an account? Register</a>
      </div>
    </center>
  );
};

export default Login;
