// client/src/pages/NotificationsPage.js
import React, { useEffect, useState } from 'react';
import notificationService from '../services/notificationService';
import styled from 'styled-components';
import '../styles.css';



const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage, setNotificationsPerPage] = useState(5);

  useEffect(() => {
    notificationService.getNotifications().then((data) => setNotifications(data));
  }, []);

  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
  const currentNotifications = notifications.slice(indexOfFirstNotification, indexOfLastNotification);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handlePerPageChange = (e) => {
    setNotificationsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page when per page changes
  };

  return (
    <div className="notifications-container">
      <h2>Your Notifications</h2>
      <div>
        <label htmlFor="perPage">Notifications per page: </label>
        <select id="perPage" value={notificationsPerPage} onChange={handlePerPageChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
        </select>
      </div>
      <div className="notification-list">
        {currentNotifications.map((notification) => (
          <div key={notification._id} className="notification-item">
            <div>
              <h4>{notification.message}</h4>
              <p>{new Date(notification.date).toLocaleString()}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <button
          disabled={indexOfLastNotification >= notifications.length}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default NotificationsPage;
