import React from 'react';

const AccessibilityStatement = () => (
  <div className="accessibility-container">
    <h1>Accessibility Statement</h1>
    <p>Last updated: [Date]</p>

    <h2>General</h2>
    <p>Pentesters Network strives to ensure that its services are accessible to people with disabilities. Pentesters Network has invested a significant amount of resources to help ensure that its website is made easier to use and more accessible for people with disabilities, with the strong belief that every person has the right to live with dignity, equality, comfort, and independence.</p>

    <h2>Accessibility on Pentesters Network</h2>
    <p>Pentesters Network makes available the UserWay Website Accessibility Widget that is powered by a dedicated accessibility server. The software allows Pentesters Network to improve its compliance with the Web Content Accessibility Guidelines (WCAG 2.1).</p>

    <h2>Enabling the Accessibility Menu</h2>
    <p>The Pentesters Network accessibility menu can be enabled by clicking the accessibility menu icon that appears on the corner of the page. After triggering the accessibility menu, please wait a moment for the accessibility menu to load in its entirety.</p>

    <h2>Disclaimer</h2>
    <p>Pentesters Network continues its efforts to constantly improve the accessibility of its site and services in the belief that it is our collective moral obligation to allow seamless, accessible, and unhindered use also for those of us with disabilities.</p>
    <p>In an ongoing effort to continually improve and remediate accessibility issues, we also regularly scan Pentesters Network with UserWay’s Accessibility Scanner to identify and fix every possible accessibility barrier on our site. Despite our efforts to make all pages and content on Pentesters Network fully accessible, some content may not have yet been fully adapted to the strictest accessibility standards. This may be a result of not having found or identified the most appropriate technological solution.</p>

    <h2>Here For You</h2>
    <p>If you are experiencing difficulty with any content on Pentesters Network or require assistance with any part of our site, please contact us during normal business hours as detailed below, and we will be happy to assist.</p>

    <h2>Contact Us</h2>
    <p>If you wish to report an accessibility issue, have any questions, or need assistance, please contact Pentesters Network Customer Support as follows:</p>
    <ul>
      <li>Email: [Your Contact Email]</li>
      <li>Phone: [Your Contact Phone Number]</li>
    </ul>
  </div>
);

export default AccessibilityStatement;
