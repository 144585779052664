import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import jobService from '../services/jobService';
import { Button } from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../styles.css';


const PostJob = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [scope, setScope] = useState('');
  const [type, setType] = useState('web');
  const [timeWindow, setTimeWindow] = useState('');
  const [webEnvironment, setWebEnvironment] = useState('production');
  const [userRoles, setUserRoles] = useState('');
  const [fileUploads, setFileUploads] = useState(false);
  const [payments, setPayments] = useState(false);
  const [virtualization, setVirtualization] = useState('');
  const [accessMethod, setAccessMethod] = useState('');
  const [passwordSpray, setPasswordSpray] = useState(false);
  const [edr, setEdr] = useState('');
  const [voipRanges, setVoipRanges] = useState('');
  const [rulesOfEngagement, setRulesOfEngagement] = useState('');
  const [statementOfWork, setStatementOfWork] = useState('');
  const [confidentialityAgreement, setConfidentialityAgreement] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const navigate = useNavigate();
  const currentUser = authService.getCurrentUser();
  const [generatedSOW, setGeneratedSOW] = useState('');
  const [generatedROE, setGeneratedROE] = useState('');
  const [showGeneratedDocs, setShowGeneratedDocs] = useState(false);

  useEffect(() => {
    if (!currentUser || currentUser.role !== 'client') {
      alert('Only clients can post jobs.');
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const jobData = {
      title,
      description,
      scope: scope.trim() === '' ? null : scope,
      type,
      timeWindow: timeWindow.trim() === '' ? null : timeWindow,
      webEnvironment: type === 'web' ? webEnvironment : null,
      userRoles: type === 'web' ? userRoles : null,
      fileUploads: type === 'web' ? fileUploads : null,
      payments: type === 'web' ? payments : null,
      virtualization: type === 'internal' ? virtualization : null,
      accessMethod: type === 'internal' ? accessMethod : null,
      passwordSpray: type === 'internal' ? passwordSpray : null,
      edr: type === 'internal' ? edr : null,
      voipRanges: type === 'internal' ? voipRanges : null,
      startDate,
      endDate,
    };

    handleGenerateDocs(jobData);
  };

  const handleFinalSubmit = async () => {
    const jobData = {
      title,
      description,
      scope: scope.trim() === '' ? null : scope,
      type,
      timeWindow: timeWindow.trim() === '' ? null : timeWindow,
      webEnvironment: type === 'web' ? webEnvironment : null,
      userRoles: type === 'web' ? userRoles : null,
      fileUploads: type === 'web' ? !!fileUploads : null,
      payments: type === 'web' ? !!payments : null,
      virtualization: type === 'internal' ? virtualization : null,
      accessMethod: type === 'internal' ? accessMethod : null,
      passwordSpray: type === 'internal' ? !!passwordSpray : null,
      edr: type === 'internal' ? edr : null,
      voipRanges: type === 'internal' ? voipRanges : null,
      startDate,
      endDate,
      rulesOfEngagement: generatedROE,
      statementOfWork: generatedSOW,
    };
  
    try {
      console.log("Job Data being sent:", jobData);
      await jobService.createJob(jobData);  // Ensure this matches your backend route
      navigate('/dashboard');
    } catch (error) {
      console.error('Failed to post job', error);
      alert('Failed to post job. Please try again.');
    }
  };

  const generateSOW = (jobData) => {
    return `
      <h2>Statement of Work (SOW)</h2>
      <p><strong>Title:</strong> ${jobData.title}</p>
      <p><strong>Description:</strong> ${jobData.description}</p>
      <p><strong>Scope:</strong> ${jobData.scope}</p>
      <p><strong>Type:</strong> ${jobData.type}</p>
      <p><strong>Start Date:</strong> ${jobData.startDate}</p>
      <p><strong>End Date:</strong> ${jobData.endDate}</p>
      <h3>Client Responsibilities:</h3>
      <ul>
        <li>Provide all necessary access and information.</li>
      </ul>
      <h3>Tester Responsibilities:</h3>
      <ul>
        <li>Conduct testing as per the scope and type defined.</li>
      </ul>
      <h3>Deliverables:</h3>
      <ul>
        <li>Detailed report of findings.</li>
      </ul>
      <h3>Acceptance Criteria:</h3>
      <ul>
        <li>All vulnerabilities are documented and explained.</li>
      </ul>
    `;
  };

  const generateROE = (jobData) => {
    return `
      <h2>Rules of Engagement (ROE)</h2>
      <p><strong>Title:</strong> ${jobData.title}</p>
      <p><strong>Description:</strong> ${jobData.description}</p>
      <p><strong>Scope:</strong> ${jobData.scope}</p>
      <p><strong>Type:</strong> ${jobData.type}</p>
      <p><strong>Start Date:</strong> ${jobData.startDate}</p>
      <p><strong>End Date:</strong> ${jobData.endDate}</p>
      <p><strong>Testing Window:</strong> ${jobData.timeWindow}</p>
      <h3>Confidentiality:</h3>
      <ul>
        <li>All findings and communications will remain confidential.</li>
      </ul>
      <h3>Testing Scope:</h3>
      <ul>
        <li>Only the specified scope will be tested.</li>
      </ul>
      <h3>Reporting:</h3>
      <ul>
        <li>Immediate reporting of critical vulnerabilities.</li>
      </ul>
    `;
  };

  const handleGenerateDocs = (jobData) => {
    const sow = generateSOW(jobData);
    const roe = generateROE(jobData);
    setGeneratedSOW(sow);
    setGeneratedROE(roe);
    setShowGeneratedDocs(true);
  };

  return (
    <div className="post-job-container">
      <h2>Post a New Job</h2>
      {!showGeneratedDocs ? (
        <form onSubmit={handleSubmit}>
          <label htmlFor="title">Job Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <label htmlFor="description">Job Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <label htmlFor="startDate">Start Date</label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
          <label htmlFor="endDate">End Date</label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
          <label htmlFor="scope">Scope</label>
          <textarea
            id="scope"
            value={scope}
            onChange={(e) => setScope(e.target.value)}
            required
          />
          <label htmlFor="type">Type of Testing</label>
          <select
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          >
            <option value="web">Web</option>
            <option value="internal">Internal Infrastructure</option>
            <option value="external">External Infrastructure</option>
            <option value="mobile">Mobile Application</option>
          </select>
          <label htmlFor="timeWindow">Time Window for Testing</label>
          <input
            type="text"
            id="timeWindow"
            value={timeWindow}
            onChange={(e) => setTimeWindow(e.target.value)}
            required
          />
          {type === 'web' && (
            <>
              <label htmlFor="webEnvironment">Type of Environment for Testing</label>
              <select
                id="webEnvironment"
                value={webEnvironment}
                onChange={(e) => setWebEnvironment(e.target.value)}
                required
              >
                <option value="production">Production</option>
                <option value="staging">Staging</option>
              </select>
              <label htmlFor="userRoles">User Roles Provided</label>
              <input
                type="text"
                id="userRoles"
                value={userRoles}
                onChange={(e) => setUserRoles(e.target.value)}
                required
              />
              <label htmlFor="fileUploads">Does the Application Take File Uploads?</label>
              <input
                type="checkbox"
                id="fileUploads"
                checked={fileUploads}
                onChange={(e) => setFileUploads(e.target.checked)}
              />
              <label htmlFor="payments">Does the Application Take Payments?</label>
              <input
                type="checkbox"
                id="payments"
                checked={payments}
                onChange={(e) => setPayments(e.target.checked)}
              />
            </>
          )}
          {type === 'internal' && (
            <>
              <label htmlFor="virtualization">What Type of Virtualization Do You Use?</label>
              <input
                type="text"
                id="virtualization"
                value={virtualization}
                onChange={(e) => setVirtualization(e.target.value)}
                required
              />
              <label htmlFor="accessMethod">How Will the Tester Access?</label>
              <input
                type="text"
                id="accessMethod"
                value={accessMethod}
                onChange={(e) => setAccessMethod(e.target.value)}
                required
              />
              <label htmlFor="passwordSpray">Do You Want a Password Spray Test on Provided Emails?</label>
              <input
                type="checkbox"
                id="passwordSpray"
                checked={passwordSpray}
                onChange={(e) => setPasswordSpray(e.target.checked)}
              />
              <label htmlFor="edr">What EDR Do You Use?</label>
              <input
                type="text"
                id="edr"
                value={edr}
                onChange={(e) => setEdr(e.target.value)}
                required
              />
              <label htmlFor="voipRanges">If You Use VOIP Devices, Please Give IP Ranges</label>
              <input
                type="text"
                id="voipRanges"
                value={voipRanges}
                onChange={(e) => setVoipRanges(e.target.value)}
              />
            </>
          )}
          <button type="submit">Generate Documents</button>
        </form>
      ) : (
        <>
          <h2>Generated Statement of Work (SOW)</h2>
          <ReactQuill value={generatedSOW} readOnly={true} theme="bubble" />
          <h2>Generated Rules of Engagement (ROE)</h2>
          <ReactQuill value={generatedROE} readOnly={true} theme="bubble" />
          <button onClick={handleFinalSubmit}>Submit Job</button>
          <button onClick={() => setShowGeneratedDocs(false)}>Edit Job Details</button>
        </>
      )}
    </div>
  );
};

export default PostJob;