import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { FaUser, FaSignInAlt, FaUserPlus, FaSignOutAlt } from 'react-icons/fa';



export const Container = styled.div`
  max-width: 2500px;
  margin: 0 auto;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Section = styled.section`
  position: relative;
  margin-bottom: 60px;
  text-align: center;
  left: -2%;
  padding: 10px;
  background: ${({ theme }) => theme.colors.lightGrey};
  border-radius: 10px;
  width: 102.5%;
  
  h1 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }

  h2 {
    color: ${({ theme }) => theme.colors.primary};
    margin-top: -10px;
    margin-bottom: 20px;
    font-size: 2.5em;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }

  p {
    font-size: 1.2em;
    color: ${({ theme }) => theme.colors.textDark};
    margin-bottom: 20px;
    line-height: 1.8;
  }
`;


export const HeaderSection = styled.section`
  position: relative;
  left:-1.5%;
  top: -22px;
  width: 103%;
  height: 500px;
  background-image: url("/background.jpeg");
  background-size: cover;
  background-position: center;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Adjust the transparency here */
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.05);
    z-index: 2;
    animation: animateOverlay 10s linear infinite;
    background-size: 200% 200%;
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.0) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.03) 50%, rgba(255, 255, 255, 0.03) 75%, transparent 75%, transparent);
  }

  @keyframes animateOverlay {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 100%;
    }
  }

  .text-content {
    position: relative;
    z-index: 3;
    max-width: 800px; /* Adjust as needed */
    text-align: center;
  }

  h1 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2em;
    line-height: 1.6;
    padding: 0 20px; /* Add padding for better alignment */
    font-sixe: 20px;
  }

  a {
    display: inline-block;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    &:hover {
      background-color: ${({ theme }) => theme.colors.dark};
    }
  }
`;



export const FeaturesSection = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: left;

  .feature {
    max-width: 250px;
    h3 {
      color: ${({ theme }) => theme.colors.primary};
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      
    }
  }
`;

export const LinkButton = styled(RouterLink)`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 18px;
  text-decoration: none;
  transition: background-color 0.3s;
  &:hover {
    background-color: #003f7f;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  div {
    text-align: center;
    margin: 20px;
    img {
      border-radius: 50%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 150px;
      height: 150px;
    }
    p {
      font-size: 16px;
      color: #333;
    }
  }
`;

export const WhyContainer = styled.div`

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  div {
    flex: 0 1 45%; 
    text-align: center;
    margin: 0px;
    img {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      width: 90%;
      height: 90%;
    }
    p {
      font-size: 20px;
      color: #333;

    }
    li {
    text-align: left;
    padding: 15px;
    list-style-type: none;
    font-size: 20px;
    }
    b, strong {
   color: #47619a;
   }
  }
`;

export const ChooseContainer = styled.div`
  position: relative;
  margin-top: -100px;
  width: 103%;
  left: -2%;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.primary};
  padding:10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  h2 {
  padding: 15px;
  color: ${({ theme }) => theme.colors.light};
  }

  p{
  color: ${({ theme }) => theme.colors.copper};
  }
  .feature {
    max-width: 250px;
    h3 {
      color: ${({ theme }) => theme.colors.light};
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      color: ${({ theme }) => theme.colors.white};
    }
  

  }
`;

export const ScreenShotContainer = styled.div`

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  div {
    flex: 0 1 45%; 
    text-align: center;
    margin: 0px;
    img {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      width: 90%;
      height: 90%;
    }
    p {
      font-size: 20px;
      color: #333;

    }
    li {
    text-align: left;
    padding: 15px;
    list-style-type: none;
    font-size: 20px;
    }
    b, strong {
   color: #47619a;
   }
  }
`;



export const TestimonialContainer = styled.div`
  display: none;
`;

export const GetStartedContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  padding: 25px;
  postion:relative;
  margin-top: -8%;
`;

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  padding: 10px 0;
  margin-top: 20px;

  a {
    color: ${({ theme }) => theme.colors.white};
    margin: 0 10px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;

export const IconButton = styled(Button)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

export const UserIcon = styled(FaUser)`
  margin-right: 10px;
`;

export const SignInIcon = styled(FaSignInAlt)`
  margin-right: 10px;
`;

export const SignOutIcon = styled(FaSignOutAlt)`
  margin-right: 10px;
`;

export const RegisterIcon = styled(FaUserPlus)`
  margin-right: 10px;
`;


export const FormContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
`;

export const InputField = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  label {
    font-size: 14px;
    color: #666;
  }

  input {
    margin-right: 5px;
  }
`;

export const ForgotPasswordLink = styled(RouterLink)`
  font-size: 14px;
  color: #47619a;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const SelectField = styled.select`
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const WhiteDiv = styled.div`
  width: 100%;
  height: 80px;
  background-color: white;
`;

export const ShadowDiv = styled.div`
  width: 101.7%;
  height: 2px; // Adjust the height as needed
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7); // Adjust the shadow as needed
  z-index: 100;
  margin-left: -19px;
  margin-top: -22px;
`;