import axios from 'axios';
import authService from './authService';

const API_URL = 'https://ptesters.net/api/notifications/';

const getNotifications = (page = 1, limit = 10) => {
  return axios.get(`${API_URL}?page=${page}&limit=${limit}`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => {
    console.log('Notifications fetched:', response.data);
    return response.data;
  }).catch(error => {
    console.error('Error fetching notifications:', error);
    throw error;
  });
};

const getNotificationCount = () => {
  return axios.get(`${API_URL}count`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => {
    console.log('Notification count fetched:', response.data.count);
    return response.data.count;
  }).catch(error => {
    console.error('Error fetching notification count:', error);
    throw error;
  });
};

export default {
  getNotifications,
  getNotificationCount
};
