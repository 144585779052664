import React, { useState, useEffect } from 'react';
import bidService from '../services/bidService';
import styled from 'styled-components';
import authService from '../services/authService';
import '../styles.css';


const BidForm = ({ jobId, onBidSubmitted }) => {
  const [amount, setAmount] = useState('');
  const [hasBid, setHasBid] = useState(false);

  useEffect(() => {
    const fetchBids = async () => {
      try {
        const bids = await bidService.getBidsForJob(jobId);
        const currentUser = authService.getCurrentUser();
        const userBid = bids.find(bid => bid.bidderId._id === currentUser.id);
        if (userBid) {
          setHasBid(true);
        }
      } catch (error) {
        console.error('Failed to fetch bids', error);
      }
    };

    fetchBids();
  }, [jobId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting bid for job:', jobId, 'Amount:', amount); // Debugging log
      await bidService.placeBid(jobId, { amount });
      setHasBid(true);
      onBidSubmitted();
    } catch (error) {
      console.error('Failed to place bid', error);
    }
  };

  if (hasBid) {
    return <div>You have already placed a bid for this job.</div>;
  }

  return (
    <div className="bid-form-container">
      <h2>Place a Bid</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="amount">Bid Amount</label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
        <button type="submit" className="bid-form-button">Submit Bid</button>
      </form>
    </div>
  );
};

export default BidForm;
