import React from 'react';
import styled from 'styled-components';
import '../styles.css';


const StarRating = ({ rating }) => {
  const totalStars = 5;
  const filledStars = Math.round(rating);
  const emptyStars = totalStars - filledStars;

  return (
    <div className="star-container">
      {[...Array(filledStars)].map((_, i) => (
        <span key={`filled-${i}`} className="star">&#9733;</span> // filled star
      ))}
      {[...Array(emptyStars)].map((_, i) => (
        <span key={`empty-${i}`} className="star">&#9734;</span> // empty star
      ))}
    </div>
  );
};

export default StarRating;
