import React from 'react';
import '../styles.css';

const Disclaimer = () => (
  <div className="disclaimer-container">
    <h1>Disclaimer</h1>
    <p>Last updated: [Date]</p>

    <h2>1. General Information</h2>
    <p>The information provided by Pentesters Network ("we," "us," or "our") on this website and our mobile application is for general informational purposes only. All information on the site and our mobile application is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site or our mobile application.</p>

    <h2>2. Professional Disclaimer</h2>
    <p>The site cannot and does not contain professional advice. The information provided is for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of professional advice. The use or reliance of any information contained on this site or our mobile application is solely at your own risk.</p>

    <h2>3. External Links Disclaimer</h2>
    <p>The site and our mobile application may contain (or you may be sent through the site or our mobile application) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.</p>

    <h2>4. Testimonials Disclaimer</h2>
    <p>The site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. Your individual results may vary.</p>

    <h2>5. Errors and Omissions Disclaimer</h2>
    <p>While we have made every attempt to ensure that the information contained in this site has been obtained from reliable sources, Pentesters Network is not responsible for any errors or omissions or for the results obtained from the use of this information. All information in this site is provided "as is," with no guarantee of completeness, accuracy, timeliness, or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to, warranties of performance, merchantability, and fitness for a particular purpose.</p>

    <h2>6. No Responsibility Disclaimer</h2>
    <p>The information on the site and our mobile application is provided with the understanding that we are not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal, or other competent advisers.</p>

    <h2>7. "Use at Your Own Risk" Disclaimer</h2>
    <p>All information in the site is provided "as is," with no guarantee of completeness, accuracy, timeliness, or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to, warranties of performance, merchantability, and fitness for a particular purpose.</p>

    <p>In no event will Pentesters Network, its related partnerships or corporations, or the partners, agents, or employees thereof be liable to you or anyone else for any decision made or action taken in reliance on the information in this site or for any consequential, special, or similar damages, even if advised of the possibility of such damages.</p>

    <h2>8. Contact Us</h2>
    <p>If you have any questions or concerns regarding this disclaimer, please contact us at [Your Contact Information].</p>
  </div>
);

export default Disclaimer;
