// client/src/services/categoryService.js
import axios from 'axios';
import authService from './authService';

const API_URL = 'https://ptesters.net/api/forum';

const getCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}/categories`, {
      headers: {
        'Authorization': `Bearer ${authService.getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

const getCategoryById = async (categoryId) => {
    try {
      const response = await axios.get(`${API_URL}/categories/${categoryId}`, {
        headers: {
          'Authorization': `Bearer ${authService.getToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching category by id:', error);
      throw error;
    }
  };

export default {
  getCategories,
  getCategoryById,
};
