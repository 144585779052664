// client/src/pages/Profile.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import userService from '../services/userService';
import authService from '../services/authService';
import ReviewForm from '../components/ReviewForm';
import ReviewList from '../components/ReviewList';
import MessageForm from '../components/MessageForm';
import MessageList from '../components/MessageList';
import styled from 'styled-components';
import { Button } from '../components/StyledComponents';
import '../styles.css';

const Profile = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [certifications, setCertifications] = useState('');
  const [experience, setExperience] = useState('');
  const [linkedin, setLinkedin] = useState('');

  useEffect(() => {
    console.log('Profile Component ID:', id);
    userService.getUserById(id)
      .then((data) => {
        setUser(data);
        setName(data.name);
        setEmail(data.email);
        setCertifications(data.certifications || '');
        setExperience(data.experience || '');
        setLinkedin(data.linkedin || '');
      })
      .catch((error) => {
        console.error('Failed to fetch user profile', error);
        setError('Failed to fetch user profile');
      });
  }, [id]);

  const handleEdit = () => setEditing(!editing);

  const handleFileChange = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    if (profilePicture) {
      formData.append('profilePicture', profilePicture);
    }
    formData.append('certifications', certifications);
    formData.append('experience', experience);
    formData.append('linkedin', linkedin);

    try {
      const updatedUser = await userService.updateUser(id, formData);
      setUser(updatedUser);
      setEditing(false);
    } catch (error) {
      console.error('Failed to update profile', error);
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!user) return <div>Loading...</div>;

  const currentUser = authService.getCurrentUser();
  const isClient = currentUser.role === 'client';

  return (
    <div className="profile-container">
      {editing ? (
        <form className="edit-form" onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <label>
            Profile Picture:
            <input type="file" onChange={handleFileChange} accept="image/*" />
          </label>
          <label>
            Certifications:
            <textarea
              value={certifications}
              onChange={(e) => setCertifications(e.target.value)}
            ></textarea>
          </label>
          <label>
            Professional Experience:
            <textarea
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
            ></textarea>
          </label>
          <label>
            LinkedIn Profile:
            <input
              type="url"
              value={linkedin}
              onChange={(e) => setLinkedin(e.target.value)}
            />
          </label>
          <Button type="submit">Save</Button>
        </form>
      ) : (
        <>
          <div className="profile-header">
            {user.profilePicture && (
              <img
                src={`${user.profilePicture}`}
                alt="Profile"
              />
            )}
            <h1>{user.name}</h1>
          </div>
          <div className="profile-details">
            <p><strong>Email:</strong> {user.email}</p>
            {user.certifications && <p><strong>Certifications:</strong> {user.certifications}</p>}
            {user.experience && <p><strong>Professional Experience:</strong> {user.experience}</p>}
            {user.linkedin && <p><strong>LinkedIn Profile:</strong> <a href={user.linkedin} target="_blank" rel="noopener noreferrer">{user.linkedin}</a></p>}
            {currentUser && currentUser.id === id && (
              <Button onClick={handleEdit}>Edit Profile</Button>
            )}
          </div>
        </>
      )}
      <center>{!isClient && <ReviewList revieweeId={id} />}
      {currentUser && currentUser.id !== id && (
        <>
          <ReviewForm revieweeId={id} onReviewSubmitted={() => {}} />
          <p></p>
          <MessageForm receiverId={id} onMessageSent={() => {}} />
          <MessageList userId={id} />
        </>
      )}</center>
    </div>
  );
};

export default Profile;
