// client/src/services/searchService.js
import axios from 'axios';
import authService from './authService';

const API_URL = 'https://ptesters.net/api/forum';

const searchPosts = async (query) => {
  try {
    const response = await axios.get(`${API_URL}/search`, {
      params: { query },
      headers: {
        'Authorization': `Bearer ${authService.getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error searching posts:', error);
    throw error;
  }
};

export default {
  searchPosts,
};
