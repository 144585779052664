// client/src/pages/Job.js
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import jobService from '../services/jobService';
import bidService from '../services/bidService';
import authService from '../services/authService';
import BidForm from '../components/BidForm';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReviewForm from '../components/ReviewForm';
import ReviewList from '../components/ReviewList';
import reviewService from '../services/reviewService';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import StripeButton from '../components/StripeButton';
import { saveChecklistProgress, getChecklistProgress } from '../services/jobService';
import stripeLogo from '../assets/stripe-logo.png';
import '../styles.css';

console.log(jobService);



// Decrypt function
const decryptData = (ciphertext, password) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, password);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

const checklists = {
  web: [
    "SQL Injection",
    "Cross-Site Scripting (XSS)",
    "Cross-Site Request Forgery (CSRF)",
    "XML External Entity (XXE) attacks",
    "Insecure Deserialization",
    "Command Injection",
    "Directory Traversal",
    "File Upload Vulnerabilities",
    "Authentication and Authorization Vulnerabilities",
    "Server-Side Request Forgery (SSRF)",
    "Business Logic Vulnerabilities",
    "WebSockets Security",
    "Cryptographic Issues",
    "Clickjacking",
    "Content Security Policy (CSP) implementation",
    "Security Misconfigurations",
    "API Security",
    "Subdomain Takeover",
    "Server-Side Template Injection (SSTI)",
    "https Host Header Attacks",
    "https Request Smuggling",
    "CORS Misconfiguration",
    "https Parameter Pollution",
    "Sensitive Data Exposure",
    "Broken Access Control",
    "Insufficient Logging and Monitoring"
  ],
  externalInfrastructure: [
    "Network Discovery",
    "Port Scanning",
    "Service Enumeration",
    "Firewall and Router Configuration Review",
    "External Vulnerability Scanning",
    "Public IP and DNS Analysis",
    "Remote Access Services Assessment",
    "Email Security and Phishing Tests",
    "Web Application Firewall (WAF) Testing",
    "Denial of Service (DoS) Testing"
  ],
  internalInfrastructure: [
    "Internal Network Scanning",
    "Patch Management Assessment",
    "Configuration Review of Critical Systems",
    "Internal Vulnerability Scanning",
    "Endpoint Security Assessment",
    "Active Directory Security Review",
    "Privilege Escalation Testing",
    "Data Loss Prevention (DLP) Testing",
    "Wireless Network Security Testing",
    "Physical Security Controls Assessment"
  ]
};

const Job = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState({ finalReport: { status: '' } });
  const [bids, setBids] = useState([]);
  const [files, setFiles] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [notification, setNotification] = useState(null);
  const currentUser = authService.getCurrentUser();
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeTab, setActiveTab] = useState('details');
  const [findings, setFindings] = useState([]);
  const [newFinding, setNewFinding] = useState('');
  const [findingTitle, setFindingTitle] = useState('');
  const [findingType, setFindingType] = useState('web');
  const [industryReference, setIndustryReference] = useState('OWASP A1: Injection');
  const [impact, setImpact] = useState('');
  const [rootCause, setRootCause] = useState('');
  const [description, setDescription] = useState('');
  const [supportingEvidence, setSupportingEvidence] = useState('');
  const [recommendation, setRecommendation] = useState('');
  const [screenshots, setScreenshots] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingFinding, setEditingFinding] = useState(null);
  const dataFetchedRef = useRef(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reviews, setReviews] = useState([]);
  const [revieweeId, setRevieweeId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [hasReviewed, setHasReviewed] = useState(false);
  const [finalReport, setFinalReport] = useState('');
  const [isSubmittingReport, setIsSubmittingReport] = useState(false);
  const [finalReportFiles, setFinalReportFiles] = useState([]);
  const [uploadPassword, setUploadPassword] = useState('');
  const [downloadPassword, setDownloadPassword] = useState('');
  const [decryptPassword, setDecryptPassword] = useState('');
  const [findingPassword, setFindingPassword] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [initialPayment, setInitialPayment] = useState(0);
  const [finalPayment, setFinalPayment] = useState(0);
  const [upfrontFee, setUpfrontFee] = useState(0);
  const [checklistItems, setChecklistItems] = useState({});
  const [currentDuePayment, setCurrentDuePayment] = useState(null);
  const [currentDueAmount, setCurrentDueAmount] = useState(0);
  const { id: jobId } = useParams();
  const [finalReportContent, setFinalReportContent] = useState('');
  const [password, setPassword] = useState('');
  


  useEffect(() => {
    if (!currentUser || dataFetchedRef.current) return;
  
    const fetchData = async () => {
      try {
        const [jobData, bidData, notificationData, reviewsData, paymentData, checklistData] = await Promise.all([
          jobService.getJobById(id),
          bidService.getBidsForJob(id),
          jobService.getNotifications(),
          reviewService.getReviewsByJobId(id),
          jobService.getPaymentDetails(id),
          jobService.getChecklistProgress(id)
        ]);
  
        setJob(jobData);
        setBids(bidData);
        setNotifications(notificationData);
        setReviews(reviewsData);
  
        if (reviewsData.length > 0 || currentUser.role === 'tester') {
          setHasReviewed(true);
        }
  
        if (jobData && (currentUser.id === jobData.clientId || (jobData.acceptedBid && jobData.acceptedBid.bidderId._id === currentUser.id))) {
          const [fileData, messageData, findingsData] = await Promise.all([
            jobService.getFiles(id),
            jobService.getMessages(id),
            jobService.getFindings(id)
          ]);
          setFiles(fileData);
          setMessages(messageData);
          setFindings(findingsData);
        }
  
        fetchBids();
  
        if (jobData.acceptedBid && jobData.acceptedBid.bidderId) {
          setRevieweeId(jobData.acceptedBid.bidderId._id);
        }
  
        if (jobData.acceptedBid) {
          const bidAmount = jobData.acceptedBid.amount;
          setInitialPayment(bidAmount * 0.5 + bidAmount * 0.1); // 50% + 10% upfront fee
          setFinalPayment(bidAmount * 0.5); // Final 50%
          setUpfrontFee(bidAmount * 0.1); // Upfront fee
  
          // Set current due payment and amount
          if (paymentData.status === 'pending') {
            setCurrentDuePayment('Initial Payment');
            setCurrentDueAmount(bidAmount * 0.5 + bidAmount * 0.1);
          } else if (paymentData.status === 'initial_paid') {
            setCurrentDuePayment('Final Payment');
            setCurrentDueAmount(bidAmount * 0.5);
          }
        }
  
        if (jobData.type && checklists[jobData.type]) {
          const initialChecklist = checklists[jobData.type].reduce((acc, item) => {
            acc[item] = checklistData[item] || false; // Use saved progress or default to false
            return acc;
          }, {});
          setChecklistItems(initialChecklist);
        }
  
        setPaymentStatus(paymentData.status);
  
        // Debugging outputs
        console.log('Payment Data:', paymentData);
        console.log('Job Data:', jobData);
        console.log('Current Due Payment:', currentDuePayment, 'Current Due Amount:', currentDueAmount);
  
      } catch (error) {
        console.error('Failed to fetch job details', error);
      }
    };
  
    fetchData();
    dataFetchedRef.current = true;
  }, [id, currentUser]);


  const calculateChecklistProgress = () => {
    const totalItems = checklists[job.type]?.length || 0;
    const completedItems = Object.values(checklistItems).filter(checked => checked).length;
    return totalItems > 0 ? Math.round((completedItems / totalItems) * 100) : 0;
  };

  const handleCheckboxChange = (item) => {
    if (currentUser.role !== 'tester') return; // Prevent clients from changing checklist
    setChecklistItems(prevItems => {
      const updatedItems = { ...prevItems, [item]: !prevItems[item] };
      jobService.saveChecklistProgress(id, updatedItems); // Save progress
      return updatedItems;
    });
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleInitialPayment = async () => {
    try {
      await jobService.makePayment(id, 'initial');
      alert('Initial payment completed.');
      setPaymentStatus('initial_paid');
    } catch (error) {
      console.error('Failed to make initial payment', error);
      alert('Failed to make initial payment. Please try again.');
    }
  };
  
  const handleFinalPayment = async () => {
    try {
      await jobService.makePayment(id, 'final');
      alert('Final payment completed.');
      setPaymentStatus('final_paid');
    } catch (error) {
      console.error('Failed to make final payment', error);
      alert('Failed to make final payment. Please try again.');
    }
  };

  const handleFindingPasswordChange = (e) => {
    setFindingPassword(e.target.value);
  };

  const handleDecryptFindings = (finding) => {
    const password = prompt('Please enter the password to decrypt the findings:');
    if (!password) {
      alert('Password is required to decrypt the findings.');
      return;
    }
  
    try {
      const decryptedFinding = {
        ...finding,
        description: finding.description ? decryptData(finding.description, password) : '',
        supportingEvidence: finding.supportingEvidence ? decryptData(finding.supportingEvidence, password) : '',
        recommendation: finding.recommendation ? decryptData(finding.recommendation, password) : '',
        screenshots: Array.isArray(finding.screenshots) ? finding.screenshots : [],
      };
      return decryptedFinding;
    } catch (error) {
      console.error('Failed to decrypt finding', error);
      alert('Failed to decrypt finding. Please check the password and try again.');
      return finding; // Return the original finding if decryption fails
    }
  };

  const handleDownloadPasswordChange = (e) => {
    setDownloadPassword(e.target.value);
  };

  const handleUploadPasswordChange = (e) => {
    setUploadPassword(e.target.value);
  };

  const handleFinalReportFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to Array
    setFinalReportFiles(files);
  };
  
  const handleSubmitFinalReport = async (e) => {
    e.preventDefault();
    if (calculateChecklistProgress() < 100) {
      alert('Checklist must be 100% complete before submitting the final report.');
      return;
    }
    if (!finalReportContent.trim() && finalReportFiles.length === 0) {
      alert('Please write the final report or upload files before submitting.');
      return;
    }
    
    setIsSubmittingReport(true);
    try {
      const reportData = {
        content: finalReportContent.trim(), // Ensure content is not empty
        files: finalReportFiles
      };
      await jobService.submitFinalReport(jobId, reportData, uploadPassword); // Use uploadPassword here
      alert('Final report submitted for quality control.');
      console.log('Submitted with password: ', uploadPassword); // Use uploadPassword here
      setIsSubmittingReport(false);
      setFinalReportContent(''); // Reset final report content
      setFinalReportFiles([]); // Clear uploaded files
    } catch (error) {
      console.error('Failed to submit final report', error);
      alert('Failed to submit final report. Please try again.');
      setIsSubmittingReport(false);
    }
  };

const handleDownloadFinalReportFile = async (fileName) => {
  try {
    const response = await jobService.downloadFinalReportFile(jobId, fileName, password);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    console.error('Failed to download final report file', err);
    // Notify user of error
  }
};

const handleDeleteFinalReportFile = async (fileName) => {
  try {
    await jobService.deleteFinalReportFile(jobId, fileName);
    // Remove file from UI
    setFinalReportFiles(finalReportFiles.filter(file => file !== fileName));
    // Notify user of success
  } catch (err) {
    console.error('Failed to delete final report file', err);
    // Notify user of error
  }
};



const fetchFinalReportFiles = async () => {
  try {
    const files = await jobService.getFinalReportFiles(jobId);
    setFinalReportFiles(files);
  } catch (err) {
    console.error('Failed to fetch final report files', err);
    // Notify user of error
  }
};



  const handleReviewSubmitted = () => {
    setHasReviewed(true);
    if (revieweeId) {
      reviewService.getReviewsByUserId(revieweeId).then((data) => {
        setReviews(data);
      });
    }
  };

  const handleSignDocument = async () => {
    try {
      await jobService.signJob(id);
      alert('Document signed successfully');
      if (currentUser.role === 'client') {
        handleInitialPayment();
      }
    } catch (error) {
      console.error('Failed to sign document', error);
      alert('Failed to sign document. Please try again.');
    }
  };
  
  const fetchMessages = async (page) => {
    try {
      const response = await jobService.getMessages(id, page);
      setMessages(response.messages);
      setCurrentPage(response.currentPage);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error('Failed to fetch messages', error);
    }
  };

  

  const fetchBids = async () => {
    try {
      const bidData = await bidService.getBidsForJob(id);
      bidData.forEach(bid => {
        console.log(`Bid ID: ${bid._id}, Status: ${bid.status}`);
      });
      const filteredBids = bidData.filter(bid => bid.status !== 'rejected');
      setBids(filteredBids);
    } catch (error) {
      console.error('Failed to fetch bids', error);
    }
  };

const handleAcceptBid = async (bidId) => {
  try {
    console.log('Calling acceptBid with bidId:', bidId);
    const result = await bidService.acceptBid(bidId);
    console.log('Bid accepted:', result);
    fetchBids(); // refresh bids
    alert('Bid accepted successfully. Job has been added to your calendar.');
  } catch (error) {
    console.error('Failed to accept bid', error);
    alert('Failed to accept bid. Please try again.');
  }
};


const handleRejectBid = async (bidId) => {
  try {
    await bidService.rejectBid(bidId);
    fetchBids(); // refresh bids
    alert('Bid rejected successfully.');
  } catch (error) {
    console.error('Failed to reject bid', error);
    alert('Failed to reject bid. Please try again.');
  }
};

  const handleFileChange = (e) => {
    const file = e.target.files[0];
  if (file.size > 1024 * 1024 * 10) { // 10 MB limit
    alert('File size should be less than 10 MB');
    setSelectedFile(null);
    return;
  }
  const allowedTypes = ['image/jpeg', 'image/png', 'application/zip', 'application/x-zip-compressed', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];
  if (!allowedTypes.includes(file.type)) {
    alert('Only JPEG, PNG, XLS, XLSX, TXT, DOC, DOCX, ZIP, and PDF files are allowed');
    setSelectedFile(null);
    return;
  }
  setSelectedFile(file);
  };

  const handleFileUpload = async () => {
    if (!selectedFile || !uploadPassword) {
      alert('File and password are required to upload.');
      return;
    }
  
    try {
      await jobService.uploadFile(id, selectedFile, uploadPassword);
      const fileData = await jobService.getFiles(id);
      setFiles(fileData);
      setSelectedFile(null);
      setUploadPassword(''); // Clear password after upload
      setNotification(`${currentUser.name} has uploaded a file for the job "${job.title}"`);
      setTimeout(() => setNotification(null), 3000);
    } catch (error) {
      console.error('Failed to upload file', error);
      alert('Failed to upload file. Please try again.');
    }
  };
  

  const handleFileDelete = async (file) => {
    try {
      await jobService.deleteFile(id, file.split('/').pop());
      const fileData = await jobService.getFiles(id);
      setFiles(fileData);
    } catch (error) {
      console.error('Failed to delete file', error);
    }
  };

  const getFileIconClass = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'fa-file-pdf';
      case 'doc':
      case 'docx':
        return 'fa-file-word';
      case 'xls':
      case 'xlsx':
        return 'fa-file-excel';
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        return 'fa-file-image';
      default:
        return 'fa-file';
    }
  };

  const handleFindingChange = (value) => {
    setNewFinding(value);
  };

  const handleFindingDelete = async (findingId) => {
    if (currentUser.role !== 'tester') {
      alert('Only testers can delete findings.');
      return;
    }
  
    console.log(`Attempting to delete finding with ID: ${findingId}`);
    try {
      await jobService.deleteFinding(id, findingId);
      console.log(`Finding ID: ${findingId} deleted successfully`);
      const findingsData = await jobService.getFindings(id);
      console.log('Updated findings data:', findingsData);
      setFindings(findingsData);
    } catch (error) {
      console.error('Failed to delete finding', error);
    }
  };
  
  const handleEditFindingClick = (finding) => {
    setEditingFinding(finding);
    setFindingTitle(finding.title);
    setFindingType(finding.type);
    setIndustryReference(finding.industryReference);
    setImpact(finding.impact);
    setRootCause(finding.rootCause);
    setDescription(finding.description);
    setSupportingEvidence(finding.supportingEvidence);
    setRecommendation(finding.recommendation);
    setIsEditing(true);
  };

  const handleEditFindingSubmit = async () => {
    if (!findingTitle || !findingType || !industryReference || !impact || !rootCause || !description || !supportingEvidence || !recommendation) {
      alert('Please fill in all required fields.');
      return;
    }
  
    try {
      const updatedFinding = {
        title: findingTitle,
        type: findingType,
        industryReference,
        impact,
        rootCause,
        description,
        supportingEvidence,
        recommendation,
        screenshots, // Assume screenshots handling remains the same
        sender: currentUser.id  // Add the sender field
      };
  
      console.log('jobService.updateFinding:', jobService.updateFinding); // Ensure it's a function
  
      await jobService.updateFinding(id, editingFinding._id, updatedFinding);
      alert('Finding updated successfully');
      const findingsData = await jobService.getFindings(id);
      setFindings(findingsData);
      setIsEditing(false);
      setEditingFinding(null);
    } catch (error) {
      console.error('Failed to update finding', error);
    }
  };

  const handleDeleteJob = async () => {
    try {
      await jobService.deleteJob(id);
      alert('Job deleted successfully');
      navigate('/dashboard'); // Redirect to dashboard or any other relevant page
    } catch (error) {
      console.error('Failed to delete job', error);
      alert('Failed to delete job. Please try again.');
    }
  };

  const handleFindingSubmit = async () => {
    if (!findingTitle || !findingType || !industryReference || !impact || !rootCause || !description || !supportingEvidence || !recommendation || !findingPassword) {
      alert('Please fill in all required fields, including password.');
      return;
    }
  
    try {
      const findingData = {
        title: findingTitle,
        type: findingType,
        industryReference,
        impact,
        rootCause,
        description,
        supportingEvidence,
        recommendation,
        screenshots: screenshots, // Array of screenshot paths
        password: findingPassword
      };
  
      await jobService.saveFinding(id, findingData);
      alert('Finding submitted successfully');
      const findingsData = await jobService.getFindings(id);
      setFindings(findingsData);
    } catch (error) {
      console.error('Failed to submit finding', error);
    }
  };

  const handleScreenshotsUpload = async (e) => {
    const files = Array.from(e.target.files); // Convert FileList to Array
    if (files.length === 0) return;
  
    const formData = new FormData();
    files.forEach(file => {
      formData.append('screenshots', file);
    });
  
    try {
      const response = await jobService.uploadScreenshots(id, formData);
      setScreenshots(response.filePaths || []);
    } catch (error) {
      console.error('Failed to upload screenshots', error);
    }
  };
  

  const handleFileDownload = async (file) => {
    const password = prompt('Please enter the password to download the file:');
    if (!password) {
      alert('Password is required to download the file.');
      return;
    }
  
    try {
      const response = await jobService.downloadFile(id, file.split('/').pop(), password);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.split('/').pop()); // Extract the file name from the path
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Failed to download file', error);
      alert('Failed to download file. Please check the password and try again.');
    }
  };
  

  const handleMessageChange = (e) => {
    setNewMessage(e.target.value);
  };

  const cleanFindingsData = (findings) => {
    return findings.map(finding => {
      if (!Array.isArray(finding.screenshots)) {
        finding.screenshots = [];
      }
      return finding;
    });
  };

  const handleMessageSend = async () => {
    if (!newMessage) return;
    try {
      const messageData = await jobService.sendMessage(id, { content: newMessage });
      setMessages(prevMessages => [...prevMessages, messageData]); // Append the new message to the existing messages
      setNewMessage('');
    } catch (error) {
      console.error('Failed to send message', error);
    }
  };

  const handleClientSign = async () => {
    try {
      await jobService.signJob(id, { clientSigned: true });
      setJob({ ...job, clientSigned: true });
    } catch (error) {
      console.error('Failed to sign job', error);
    }
  };
  
  const handleTesterSign = async () => {
    try {
      await jobService.signJob(id, { testerSigned: true });
      setJob({ ...job, testerSigned: true });
    } catch (error) {
      console.error('Failed to sign job', error);
    }
  };

  if (!job) return <div>Loading...</div>;

  // Debugging outputs
  // console.log('Accepted Bid:', job.acceptedBid);
  // console.log('Bidder ID:', job.acceptedBid?.bidderId?._id);
 // console.log('Current User ID:', currentUser.id);

 return (
  <div className="job-page-container">
    <div className="job-page-sidebar">
      <div className="job-page-sidebar-item" onClick={() => handleTabChange('details')}>Job Details</div>
      <div className="job-page-sidebar-item" onClick={() => handleTabChange('bids')}>Bids</div>
      {job.acceptedBid && (
        <>
          <div className="job-page-sidebar-item" onClick={() => handleTabChange('checklist')}>Checklist</div>
          <div className="job-page-sidebar-item" onClick={() => handleTabChange('files')}>Files</div>
          <div className="job-page-sidebar-item" onClick={() => handleTabChange('messages')}>Messages</div>
          <div className="job-page-sidebar-item" onClick={() => handleTabChange('findings')}>Findings</div>
          <div className="job-page-sidebar-item" onClick={() => handleTabChange('reviews')}>Reviews</div>
          <div className="job-page-sidebar-item" onClick={() => handleTabChange('finalReport')}>Final Report</div>
        </>
      )}
    </div>
    <div className="job-page-main-content">
      <div className="job-page-content-container">
        {activeTab === 'details' && (
          <div className="job-page-card-container">
            <div className="job-page-card">
              <h3>Job Details</h3>
              <p><strong>Title:</strong> {job.title}</p>
              <p><strong>Description:</strong> {job.description}</p>
              <p><strong>Scope:</strong> {job.scope}</p>
              <p><strong>Type:</strong> {job.type}</p>
              <p><strong>Time Window:</strong> {job.timeWindow}</p>
              <p><strong>Start Date:</strong> {new Date(job.startDate).toLocaleDateString()}</p>
              <p><strong>End Date:</strong> {new Date(job.endDate).toLocaleDateString()}</p>
              <p><strong>Checklist Progress:</strong> {calculateChecklistProgress()}%</p>
              {currentUser.role === 'tester' && <p><strong>Final Report Status:</strong> {job.finalReport.status}</p>}
            </div>
            {job.acceptedBid && (
              <div className="job-page-card">
                <h3>Payment Details</h3>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <div>
                    <p><strong>Initial Payment:</strong> ${initialPayment} {paymentStatus === 'initial_paid' && <span>(Paid)</span>}</p>
                    <p><strong>Final Payment:</strong> ${finalPayment} {paymentStatus === 'final_paid' && <span>(Paid)</span>}</p>
                    <p><strong>Included Upfront Fee:</strong> ${upfrontFee} {paymentStatus === 'initial_paid' && <span>(Paid)</span>}</p>

                    {currentUser.role === 'client' && currentDuePayment && (
                      <p><strong>Current Due:</strong> {currentDuePayment} - ${currentDueAmount}</p>
                    )}

                    {currentUser.id === job.clientId && paymentStatus === 'pending' && (
                      <StripeButton jobId={id} paymentType="initial" onSuccess={handleInitialPayment} />
                    )}

                    {currentUser.id === job.clientId && paymentStatus === 'initial_paid' && (
                      <StripeButton jobId={id} paymentType="final" onSuccess={handleFinalPayment} />
                    )}
                    <img src={stripeLogo} alt="Stripe Logo" style={{ width: '150px', }} />
                  </div>
                  {job.acceptedBid && (
                    <div style={{ marginRight: '30%' }}>
                      <h4>Accepted Bid</h4>
                      <p>Bidder: <Link to={`/profile/${job.acceptedBid.bidderId._id}`}>{job.acceptedBid.bidderId.name}</Link></p>
                      <p>Amount: ${job.acceptedBid.amount}</p>
                    </div>
                  )}
                </div>
                {currentUser.role === 'tester' && (
                  <div>
                    <p><strong>Payment Status:</strong> {paymentStatus === 'initial_paid' ? 'Initial Payment Completed' : paymentStatus === 'final_paid' ? 'Final Payment Completed' : 'Pending'}</p>
                  </div>
                )}
              </div>
            )}
            <div className="job-page-horizontal-card-container">
              {job.acceptedBid && job.rulesOfEngagement && (
                <div className="job-page-card">
                  <h4>Rules of Engagement</h4>
                  <ReactQuill value={job.rulesOfEngagement} readOnly={true} theme="bubble" />
                </div>
              )}

              {job.acceptedBid && job.statementOfWork && (
                <div className="job-page-card">
                  <h4>Statement of Work</h4>
                  <ReactQuill value={job.statementOfWork} readOnly={true} theme="bubble" />
                </div>
              )}
            </div>
            {job.status === 'accepted' && (
              <>
                {!job.clientSigned && currentUser.role === 'client' && (
                  <button onClick={handleSignDocument}>Sign Document</button>
                )}
                {!job.testerSigned && currentUser.role === 'tester' && (
                  <button onClick={handleSignDocument}>Sign Document</button>
                )}
              </>
            )}
          </div>
        )}
        {activeTab === 'checklist' && (
          <div className="job-page-card-container">
            <div className="job-page-card">
              <div className="job-page-checkboxes">
                <h3>{currentUser.role === 'client' ? 'Tester Progress' : 'Checklist for ' + job.type + ' Testing'}</h3>
                <p>Progress: {calculateChecklistProgress()}%</p>
                <ul>
                  {checklists[job.type] ? checklists[job.type].map((item, index) => (
                    <li key={index}>
                      <label className="job-page-checkbox-label">
                        <input
                          type="checkbox"
                          checked={checklistItems[item] || false}
                          onChange={() => handleCheckboxChange(item)}
                        />
                        {item}
                      </label>
                    </li>
                  )) : <li>No checklist available for this job type</li>}
                </ul>
              </div>
            </div>
          </div>
        )}
        {activeTab === 'bids' && (
          <div className="bid-details">
            <h2>Bids</h2>
            {bids.length > 0 ? (
              bids.map(bid => (
                bid.status !== 'rejected' && (
                  <div key={bid._id} className="bids">
                    <p>Bidder: {currentUser.role === 'client' ? bid.bidderId.name : 'You'}</p>
                    <p>Amount: ${bid.amount}</p>
                    <p>Date: {new Date(bid.date).toLocaleDateString()}</p>
                    {currentUser.role === 'client' && !job.acceptedBid && (
                      <>
                        <button onClick={() => handleAcceptBid(bid._id)}>Accept</button>
                        <button onClick={() => handleRejectBid(bid._id)}>Reject</button>
                      </>
                    )}
                  </div>
                )
              ))
            ) : (
              <p>No bids yet.</p>
            )}
          </div>
        )}
        {currentUser.role === 'tester' && !job?.acceptedBid && <BidForm jobId={id} onBidSubmitted={fetchBids} />}
        {activeTab === 'files' && (
          <div className="file-details">
            <h2>Shared Files</h2>
            {files.length > 0 ? (
              files.map((file, index) => (
                <div key={index} className="files">
                  <button onClick={() => handleFileDownload(file)}>
                    <i className={`file-icon ${getFileIconClass(file)}`}></i>
                    {file.split('/').pop()}
                  </button>
                  {(currentUser.role === 'client' || job.acceptedBid?.bidderId._id === currentUser.id) && (
                    <button onClick={() => handleFileDelete(file)}>Delete</button>
                  )}
                </div>
              ))
            ) : (
              <p>No files shared yet.</p>
            )}
            {(currentUser.role === 'client' || (job.acceptedBid && job.acceptedBid.bidderId._id === currentUser.id)) && (
              <>
                <input className="job-page-file-input" type="file" onChange={handleFileChange} />
                <input className="job-page-input" type="password" placeholder="Enter upload password" value={uploadPassword} onChange={handleUploadPasswordChange} />
                <button onClick={handleFileUpload}>Upload File</button>
              </>
            )}
          </div>
        )}
        {activeTab === 'messages' && (
          <div className="message-details">
            <h2>Messages</h2>
            {messages.length > 0 ? (
              messages.map((message, index) => (
                <div key={index} className="messages">
                  <strong>{message.sender.name}</strong>: {message.content} <small>({new Date(message.timestamp).toLocaleString()})</small>
                </div>
              ))
            ) : (
              <p>No messages yet.</p>
            )}
            <div className="job-page-pagination-container">
              <button onClick={() => fetchMessages(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
              <button onClick={() => fetchMessages(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
            </div>
            <textarea className="job-page-message-input" value={newMessage} onChange={handleMessageChange} />
            <button onClick={handleMessageSend}>Send</button>
          </div>
        )}
        {activeTab === 'findings' && (
          <div className="finding-details">
            <h2>Findings</h2>
            {findings.length > 0 ? (
              findings.map((finding, index) => {
                const decryptedFinding = handleDecryptFindings(finding);
                const screenshots = Array.isArray(decryptedFinding.screenshots) ? decryptedFinding.screenshots : [];
                return (
                  <div key={index} className="finding">
                    <h3>{decryptedFinding.title}</h3>
                    <p><u>Type:</u> {decryptedFinding.type}</p>
                    <p><u>Industry Reference:</u> {decryptedFinding.industryReference}</p>
                    <p><u>Impact:</u> {decryptedFinding.impact}</p>
                    <p><u>Root Cause:</u> {decryptedFinding.rootCause}</p>
                    <p><u>Description:</u></p>
                    <div dangerouslySetInnerHTML={{ __html: decryptedFinding.description }}></div>
                    <p><u>Supporting Evidence:</u></p>
                    <div dangerouslySetInnerHTML={{ __html: decryptedFinding.supportingEvidence }}></div>
                    <p><u>Screenshots:</u></p>
                    {screenshots.map((screenshot, idx) => (
                      <img key={idx} src={`https://ptesters.net${screenshot}`} alt="Screenshot" style={{ maxWidth: '100%' }} />
                    ))}
                    <p><u>Recommendation:</u></p>
                    <div dangerouslySetInnerHTML={{ __html: decryptedFinding.recommendation }}></div>
                    {currentUser.role === 'tester' && (
                      <>
                        <button onClick={() => handleEditFindingClick(finding)}>Edit</button>
                        <button onClick={() => handleFindingDelete(finding._id)}>Delete</button>
                      </>
                    )}
                  </div>
                );
              })
            ) : (
              <p>No findings yet.</p>
            )}
            {currentUser.role === 'tester' && (isEditing ? (
              <div>
                <h3>Edit Finding</h3>
                <input className="job-page-input" type="text" value={findingTitle} onChange={(e) => setFindingTitle(e.target.value)} placeholder="Title" />
                <select className="job-page-dropdown" value={findingType} onChange={(e) => setFindingType(e.target.value)}>
                  <option value="web">Web</option>
                  <option value="mobile">Mobile</option>
                  <option value="network">Network</option>
                </select>
                <input className="job-page-input" type="text" value={industryReference} onChange={(e) => setIndustryReference(e.target.value)} placeholder="Industry Reference" />
                <input className="job-page-input" type="text" value={impact} onChange={(e) => setImpact(e.target.value)} placeholder="Impact" />
                <input className="job-page-input" type="text" value={rootCause} onChange={(e) => setRootCause(e.target.value)} placeholder="Root Cause" />
                <ReactQuill className="job-page-finding-input" value={description} onChange={setDescription} placeholder="Description" />
                <ReactQuill className="job-page-finding-input" value={supportingEvidence} onChange={setSupportingEvidence} placeholder="Supporting Evidence" />
                <ReactQuill className="job-page-finding-input" value={recommendation} onChange={setRecommendation} placeholder="Recommendation" />
                <input className="job-page-file-upload" type="file" multiple onChange={handleScreenshotsUpload} />
                <input className="job-page-input" type="password" placeholder="Enter password" value={findingPassword} onChange={handleFindingPasswordChange} />
                <button onClick={handleEditFindingSubmit}>Submit</button>
                <button onClick={() => setIsEditing(false)}>Cancel</button>
              </div>
            ) : (
              <div>
                <h3>Add New Finding</h3>
                <input className="job-page-input" type="text" value={findingTitle} onChange={(e) => setFindingTitle(e.target.value)} placeholder="Title" />
                <select className="job-page-dropdown" value={findingType} onChange={(e) => setFindingType(e.target.value)}>
                  <option value="web">Web</option>
                  <option value="mobile">Mobile</option>
                  <option value="network">Network</option>
                </select>
                <input className="job-page-input" type="text" value={industryReference} onChange={(e) => setIndustryReference(e.target.value)} placeholder="Industry Reference" />
                <input className="job-page-input" type="text" value={impact} onChange={(e) => setImpact(e.target.value)} placeholder="Impact" />
                <input className="job-page-input" type="text" value={rootCause} onChange={(e) => setRootCause(e.target.value)} placeholder="Root Cause" />
                <ReactQuill className="job-page-finding-input" value={description} onChange={setDescription} placeholder="Description" />
                <ReactQuill className="job-page-finding-input" value={supportingEvidence} onChange={setSupportingEvidence} placeholder="Supporting Evidence" />
                <ReactQuill className="job-page-finding-input" value={recommendation} onChange={setRecommendation} placeholder="Recommendation" />
                <input className="job-page-file-upload" type="file" multiple onChange={handleScreenshotsUpload} />
                <input className="job-page-input" type="password" placeholder="Enter password" value={findingPassword} onChange={handleFindingPasswordChange} />
                <button onClick={handleFindingSubmit}>Submit</button>
              </div>
            ))}
          </div>
        )}
        {activeTab === 'finalReport' && currentUser.role === 'tester' && job.status === 'accepted' && (
          <div className="final-report">
            <h3>Submit Final Report</h3>
            <input className="job-page-file-input" type="file" multiple onChange={handleFinalReportFileChange} />
            <input
              className="job-page-input"
              type="password"
              placeholder="Enter upload password"
              value={uploadPassword}
              onChange={handleUploadPasswordChange} // Ensure this correctly updates the state
            />
            <button onClick={handleSubmitFinalReport} disabled={isSubmittingReport || calculateChecklistProgress() < 100}>
              Submit Final Report
            </button>
            {job.finalReport.status === 'rejected' && (
              <div>
                <p>The final report was rejected. Please resubmit after addressing the comments.</p>
                <p></p>
                <p><strong>Rejection Notes:</strong></p>
                <div dangerouslySetInnerHTML={{ __html: job.finalReport.notes }}></div>
              </div>
            )}
          </div>
        )}
        {activeTab === 'finalReport' && currentUser.role === 'client' && (
          <div className="final-report-status">
            <h3>Final Report Status</h3>
            {job.finalReport.status === 'pending' && <p>The final report has been submitted and is awaiting QA approval.</p>}
            {job.finalReport.status === 'approved' && paymentStatus === 'final_paid' && (
              <div>
                <p>The final report has been approved and is available for download.</p>
                <button onClick={() => handleFileDownload(job.finalReport.files[0])}>Download Final Report</button>
              </div>
            )}
            {job.finalReport.status === 'rejected' && (
              <div>
                <p>The final report has been submitted and is awaiting QA approval.</p>
              </div>
            )}
          </div>
        )}
        {activeTab === 'reviews' && (
          <div className="review-details">
            <h2>Tester Reviews</h2>
            {job.acceptedBid && currentUser.role === 'client' && revieweeId && !hasReviewed && (
              <>
                <ReviewForm revieweeId={revieweeId} jobId={job._id} onReviewSubmitted={handleReviewSubmitted} />
                {console.log('Rendering ReviewForm with revieweeId:', revieweeId, 'and jobId:', job._id)}
              </>
            )}
            <ReviewList userId={userId} revieweeId={revieweeId} jobId={job._id}/> {/* Pass userId to ReviewList */}
          </div>
        )}
        {notification && <div className="job-page-notification">{notification}</div>}
      </div>
    </div>
  </div>
);
};


export default Job;
