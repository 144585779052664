// client/src/services/messageService.js
import axios from 'axios';
import authService from './authService';

const API_URL = 'https://ptesters.net/api/messages/';

const sendMessage = (messageData) => {
  return axios.post(API_URL, messageData, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const getMessages = (userId) => {
  return axios.get(API_URL + userId, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

export default {
  sendMessage,
  getMessages
};
