// client/src/components/Modal.js
import React from 'react';
import styled from 'styled-components';
import '../styles.css';



const Modal = ({ children, onClose }) => {
  return (
    <div className="modal-background" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
