import React from 'react';
import { LinkButton, Container, ImageContainer, Section, HeaderSection, FeaturesSection, WhiteDiv, ShadowDiv, WhyContainer, ChooseContainer, TestimonialContainer, GetStartedContainer, ScreenShotContainer } from '../components/StyledComponents';
import '../styles.css';

// Import images
import tester1 from '../assets/tester1.jpg';
import secure from '../assets/secure.png';
import experience from '../assets/experience.png';
import microscope from '../assets/microscope.png';
import quality from '../assets/quality.png';
import SS from '../assets/SS.png';
import janedoe from '../assets/janedoe.jpg';
import john from '../assets/john.jpg';
import mary from '../assets/mary.jpg';

const Home = () => {
  return (
    <>
      <div className="home-shadow-div" />
      <div className="home-white-div">
        <center><h2>Secure Your Company's Assets For Less</h2></center>
      </div>
      <div className="home-header-section">
        <div className="overlay"></div>
        <div className="text-content">
          <p>
            Connect with independent, experienced penetration testers to enhance your data security while reducing costs. Make penetration testing accessible and affordable for everyone.
          </p>
          <a href="/register" className="link-button">Get Started</a>
        </div>
      </div>
      <div className="home-container">
        <section className="home-section">
          <div className="home-why-container">
            <div>
              <img src={tester1} alt="Post a Project" />
            </div>
            <div>
              <h2>How It Works</h2>
              <li><b>Post a Project</b> - Submit your penetration test project to our platform for free.</li>
              <li><b>Select a Tester</b> - Experienced penetration testers bid on the job. Choose the best fit based on budget and expertise.</li>
              <li><b>Receive a Report</b> - The tester completes the job and submits an encrypted final report, which undergoes a rigorous quality assurance process.</li>
              <a href="/register" className="link-button">Sign Up Today</a>
            </div>
          </div>
        </section>
        <section className="home-section">
          <div className="home-choose-container">
            <center><h2>Why Choose Us</h2></center>
            <div className="home-features-section">
              <div className="feature">
                <div className="home-image-container"><img src={secure} alt="Secure and Reliable" /></div>
                <h3>Secure and Reliable</h3>
                <p>Our platform ensures a seamless experience for clients and testers. All reports and findings are encrypted to protect your data.</p>
              </div>
              <div className="feature">
                <div className="home-image-container"><img src={experience} alt="Experienced Testers" /></div>
                <h3>Experienced Testers</h3>
                <p>We verify penetration testers with years of experience. Our review system and detailed profiles help you choose the right tester for your project.</p>
              </div>
              <div className="feature">
                <div className="home-image-container"><img src={microscope} alt="Transparent Process" /></div>
                <h3>Transparent Process</h3>
                <p>Our bidding process is transparent, reducing costs and overhead. Find the best tester for your project quickly and easily.</p>
              </div>
              <div className="feature">
                <div className="home-image-container"><img src={quality} alt="Quality Assurance" /></div>
                <h3>Quality Assurance</h3>
                <p>Every final report undergoes a thorough quality assurance check before payment, ensuring the highest quality results.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="home-section">
          <div className="home-screenshot-container">
            <div>
              <h2>Effortless Penetration Testing Management</h2>
              <p>Manage your entire penetration test with our all-in-one dashboard. Centralize job details, payments, communication, bids, and files in one place. Simplify your workflow and focus on securing your project with the industry's best testers.</p>
            </div>
            <div>
              <img src={SS} alt="Dashboard Screenshot" />
            </div>
          </div>
        </section>
        <section className="home-section">
          <div className="home-testimonial-container">
            <h2>Testimonials</h2>
            <div className="home-image-container">
              <div>
                <img src={janedoe} alt="Jane Doe" />
                <p>Jane Doe</p>
                <p>"Pentesters Network made it so easy to find quality testers for our security needs. Highly recommend!"</p>
              </div>
              <div>
                <img src={john} alt="John Smith" />
                <p>John Smith</p>
                <p>"The platform is user-friendly and the testers are top-notch. Great experience overall!"</p>
              </div>
              <div>
                <img src={mary} alt="Mary Johnson" />
                <p>Mary Johnson</p>
                <p>"I found the perfect tester for my project quickly and efficiently. Fantastic service!"</p>
              </div>
            </div>
          </div>
        </section>
        <section className="home-section">
          <div className="home-get-started-container">
            <center><h2>Ready to Get Started?</h2></center>
            <p>Join Pentesters Network today and secure your company assets with the industry's best penetration testers.</p>
            <center><a href="/register" className="link-button">Sign Up Now</a></center>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
