// client/src/components/StripeButton.js
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import jobService from '../services/jobService';

const stripePromise = loadStripe('YOUR_PUBLIC_STRIPE_KEY');

const CheckoutForm = ({ jobId, paymentType, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error(error);
    } else {
      // Call your backend to create the payment
      const paymentResult = await jobService.createStripePayment(jobId, paymentType, paymentMethod.id);

      if (paymentResult.error) {
        console.error(paymentResult.error);
      } else {
        alert('Payment successful');
        onSuccess();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};

const StripeButton = ({ jobId, paymentType, onSuccess }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm jobId={jobId} paymentType={paymentType} onSuccess={onSuccess} />
    </Elements>
  );
};

export default StripeButton;
