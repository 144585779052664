// client/src/pages/ForumPage.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import categoryService from '../services/categoryService';
import SearchBar from '../components/SearchBar';
import CreatePostForm from '../components/CreatePostForm';
import Modal from '../components/Modal';
import { Button } from '../components/StyledComponents';
import '../styles.css';



const ForumPage = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await categoryService.getCategories();
        setCategories(categoriesData);
      } catch (error) {
        setError('Failed to fetch categories');
        console.error('Failed to fetch categories', error);
      }
    };

    fetchCategories();
  }, []);

  const handleCreatePost = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="forum-page-container">
      <h1>Forums</h1>
      <SearchBar />
      <Button onClick={handleCreatePost}>Create Post</Button>
      <p></p>
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <CreatePostForm onPostCreated={handleCloseModal} />
        </Modal>
      )}
      {error && <p>{error}</p>}
      {categories.map((category) => (
        <div key={category._id} className="category-card">
          <h2 className="category-header">{category.name}</h2>
          <p>{category.description}</p>
          <Link to={`/category/${category._id}/posts`} className="view-posts-link">View All Posts</Link>
        </div>
      ))}
    </div>
  );
};

export default ForumPage;
