// client/src/components/AdminModal.js
import React from 'react';
import '../styles.css';


const Modal = ({ show, onClose, onSave, title, children }) => {
  return (
    <div className={`admin-modal-overlay ${show ? 'show' : ''}`}>
      <div className="admin-modal-content">
        <div className="admin-modal-header">
          <h2>{title}</h2>
          <button onClick={onClose}>X</button>
        </div>
        <div>{children}</div>
        <div className="admin-modal-footer">
          <button className="admin-modal-button" onClick={onClose}>Cancel</button>
          <button className="admin-modal-button" onClick={onSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
