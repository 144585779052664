// client/src/services/commentService.js
import axios from 'axios';
import authService from './authService';

const API_URL = 'https://ptesters.net/api/forum';

const getComments = async (postId, page = 1, limit = 10) => {
    try {
      const response = await axios.get(`${API_URL}/posts/${postId}/comments`, {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching comments:', error);
      throw error;
    }
  };

const createComment = async (postId, commentData) => {
  try {
    const token = authService.getToken();
    const response = await axios.post(`${API_URL}/posts/${postId}/comments`, commentData, {
      headers: {
        'x-auth-token': token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating comment:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default {
  getComments,
  createComment,
};
