// client/src/pages/InfrastructureTestingStandards.js
import React from 'react';
import '../styles.css';

const InfrastructureTestingStandards = () => {
  return (
    <div className="infrastructure-container">
      <h1 className="infrastructure-header">Infrastructure Testing Standards</h1>
      
      <div className="infrastructure-section">
        <h2 className="infrastructure-subheader">External Infrastructure Testing</h2>
        <ul className="infrastructure-list">
          <li>Network Discovery</li>
          <li>Port Scanning</li>
          <li>Service Enumeration</li>
          <li>Firewall and Router Configuration Review</li>
          <li>External Vulnerability Scanning</li>
          <li>Public IP and DNS Analysis</li>
          <li>Remote Access Services Assessment</li>
          <li>Email Security and Phishing Tests</li>
          <li>Web Application Firewall (WAF) Testing</li>
          <li>Denial of Service (DoS) Testing</li>
        </ul>
      </div>

      <div className="infrastructure-section">
        <h2 className="infrastructure-subheader">Internal Infrastructure Testing</h2>
        <ul className="infrastructure-list">
          <li>Internal Network Scanning</li>
          <li>Patch Management Assessment</li>
          <li>Configuration Review of Critical Systems</li>
          <li>Internal Vulnerability Scanning</li>
          <li>Endpoint Security Assessment</li>
          <li>Active Directory Security Review</li>
          <li>Privilege Escalation Testing</li>
          <li>Data Loss Prevention (DLP) Testing</li>
          <li>Wireless Network Security Testing</li>
          <li>Physical Security Controls Assessment</li>
        </ul>
      </div>
    </div>
  );
};

export default InfrastructureTestingStandards;
