import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in error boundary:", error, errorInfo);
    // Redirect to homepage
    window.location.href = '/';
  }

  render() {
    if (this.state.hasError) {
      return null; // or you can return a custom error component if you prefer
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
