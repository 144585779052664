import React, { useState } from 'react';
import reviewService from '../services/reviewService';
import authService from '../services/authService';  // Import authService
import { FormContainer, InputField, Button, SelectField } from '../components/StyledComponents';


const ReviewForm = ({ revieweeId, jobId, onReviewSubmitted }) => {
  const [rating, setRating] = useState(1);
  const [comment, setComment] = useState('');
  const currentUser = authService.getCurrentUser();  // Get current user

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reviewerId = currentUser.id;  // Get reviewerId from current user
    console.log('Reviewer ID:', reviewerId);  // Debugging log
    try {
      await reviewService.createReview({ reviewerId, revieweeId, jobId, rating, comment });
      alert('Review submitted successfully');
      setRating(1);
      setComment('');
      onReviewSubmitted();
    } catch (error) {
      console.error('Failed to submit review', error);
      alert('Failed to submit review');
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <h2>Leave a Review</h2>
      <label>
        Rating:
        <SelectField value={rating} onChange={(e) => setRating(e.target.value)} required>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </SelectField>
      </label>
      <label>
        Comment:
        <InputField
          as="textarea"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          required
        ></InputField>
      </label>
      <Button type="submit">Submit Review</Button>
    </FormContainer>
  );
};

export default ReviewForm;
