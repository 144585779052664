// client/src/services/adminService.js
import axios from 'axios';
import authService from './authService';

const API_URL = 'https://ptesters.net/api/admin/';

const getUsers = () => {
  return axios.get(API_URL + 'users', {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const getJobs = () => {
  return axios.get(API_URL + 'jobs', {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const updateUserRole = (userId, newRole) => {
  return axios.put(`${API_URL}users/${userId}/role`, { role: newRole }, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const deleteUser = (userId) => {
  return axios.delete(`${API_URL}users/${userId}`, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const deleteJob = (jobId) => {
    return axios.delete(`${API_URL}jobs/${jobId}`, {
      headers: { 'x-auth-token': authService.getToken() }
    }).then(response => response.data);
  };

  const getPendingReports = (page, limit) => {
    return axios.get(`${API_URL}reports`, {
      params: { page, limit },
      headers: { 'x-auth-token': authService.getToken() }
    }).then(response => response.data);
  };
  
  const approveReport = (jobId, reportId) => {
    return axios.put(`${API_URL}jobs/${jobId}/reports/${reportId}/approve`, {}, {
      headers: { 'x-auth-token': authService.getToken() }
    }).then(response => response.data);
  };
  
  const rejectReport = (jobId, reportId, notes) => {
    return axios.put(`${API_URL}jobs/${jobId}/reports/${reportId}/reject`, { notes }, {
      headers: { 'x-auth-token': authService.getToken() }
    }).then(response => response.data);
  };

  const getReports = () => {
    return axios.get(API_URL + 'reports', {
      headers: { 'x-auth-token': authService.getToken() }
    }).then(response => response.data);
  };
  
  

  const downloadFinalReportFile = (jobId, filename, password) => {
    console.log(`API request to download final report file with password: ${password}`);  // Debug log
    return axios.post(
      `${API_URL}${jobId}/final-report/files/${filename}`,
      { password },  // Ensure the password is included in the request body
      {
        headers: {
          'x-auth-token': authService.getToken(),
        },
        responseType: 'blob',
      }
    ).then(response => response);
  };
  

const submitFinalReport = async (jobId, reportData) => {
  console.log(`Submitting final report for job ID: ${jobId}`);
  console.log(`Report data:`, reportData);

  try {
      const response = await axios.post(`${API_URL}${jobId}/finalReport`, reportData, {
          headers: { 'x-auth-token': authService.getToken() }
      });
      console.log(`Final report submission response:`, response.data);
      return response.data;
  } catch (error) {
      console.error('Failed to submit final report', error);
      throw error;
  }
};

export default {
  getUsers,
  getJobs,
  updateUserRole,
  deleteUser,
  deleteJob,
  getPendingReports,
  approveReport,
  getReports,
  rejectReport,
  downloadFinalReportFile,
  submitFinalReport,
};
