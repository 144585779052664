import api from '../interceptors/axiosInstance';

const register = (name, email, password, role) => {
  return api.post('/auth/register', {
    name,
    email,
    password,
    role
  });
};

const login = (email, password) => {
  return api.post('/auth/login', {
    email,
    password
  }).then((response) => {
    console.log('Backend Response:', response.data); // Log the backend response
    if (response.data.token && response.data.user) {
      localStorage.setItem('user', JSON.stringify(response.data.user)); // Store user object
      localStorage.setItem('token', response.data.token); // Store token separately
    }
    return response.data;
  });
};

const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
};

const getCurrentUser = () => {
  const user = localStorage.getItem('user');
  if (user) {
    try {
      return JSON.parse(user);
    } catch (error) {
      console.error('Error parsing user JSON:', error);
      localStorage.removeItem('user'); // Remove corrupted user data
    }
  }
  return null;
};

const getToken = () => {
  return localStorage.getItem('token');
};

const redirectToLogin = () => {
  logout();
  window.location.href = '/login';
};

const forgotPassword = async (email) => {
  await api.post('/auth/forgot-password', { email });
};

export default {
  register,
  login,
  logout,
  getCurrentUser,
  getToken,
  redirectToLogin,
  forgotPassword,
};
