// client/src/services/userService.js
import axios from 'axios';
import authService from './authService';

const API_URL = 'https://ptesters.net/api/users/';

const getUserById = (id) => {
  return axios.get(API_URL + id, {
    headers: { 'x-auth-token': authService.getToken() }
  }).then(response => response.data);
};

const updateUser = (id, userData) => {
  return axios.post(API_URL + 'profile-picture', userData, {
    headers: {
      'x-auth-token': authService.getToken(),
      'Content-Type': 'multipart/form-data',
    },
  }).then(response => response.data);
};

export default {
  getUserById,
  updateUser,
};
