// client/src/pages/FAQ.js
import React from 'react';
import '../styles.css';



const FAQ = () => {
  return (
    <div className="faq-container">
      <h1 className="faq-header">Frequently Asked Questions</h1>
      <div className="faq-item">
        <h2 className="faq-question">What are the testing standards?</h2>
        <p className="faq-answer">
          Our testing standards are comprehensive guidelines that outline the scope and depth of testing that our independent testers are required to perform. These standards include various aspects of web and infrastructure security, covering a wide range of vulnerabilities and attack vectors.
        </p>
      </div>
      <div className="faq-item">
        <h2 className="faq-question">What does the Web Testing cover?</h2>
        <p className="faq-answer">
          The Web Testing covers areas such as SQL Injection, Cross-Site Scripting (XSS), Cross-Site Request Forgery (CSRF), Security Misconfigurations, and many more. A detailed list can be found on our Web Testing Standards page.
        </p>
      </div>
      <div className="faq-item">
        <h2 className="faq-question">What does the Infrastructure Testing cover?</h2>
        <p className="faq-answer">
          The Infrastructure Testing includes Network Security, Server Configuration, Patch Management, Vulnerability Scanning, Penetration Testing, and more. Please refer to our Infrastructure Testing Standards page for a detailed checklist.
        </p>
      </div>
      <div className="faq-item">
        <h2 className="faq-question">How can I choose the best tester for my project?</h2>
        <p className="faq-answer">
          You can view the credentials of each tester on their profile page, which includes their experience, certifications, and reviews from previous clients. This information will help you select the tester that best fits your project's requirements.
        </p>
      </div>
      <div className="faq-item">
        <h2 className="faq-question">How do I contact support?</h2>
        <p className="faq-answer">
          You can contact our support team via the Contact Us page. We are here to help you with any questions or issues you may have.
        </p>
      </div>
    </div>
  );
};

export default FAQ;
