import React from 'react';
import '../styles.css';

const TermsOfService = () => (
  <div className="terms-of-service-container">
    <h1>Terms of Service</h1>
    <p>Last updated: [Date]</p>

    <h2>1. Introduction</h2>
    <p>Welcome to Pentesters Network. These Terms of Service govern your use of our website and services. By accessing or using our services, you agree to comply with these terms.</p>

    <h2>2. Account Registration</h2>
    <p>To use certain features of our services, you must register for an account. You agree to provide accurate and complete information during the registration process and to keep your account information up-to-date.</p>

    <h2>3. User Conduct</h2>
    <p>You agree not to use our services for any unlawful or prohibited activities. You will not:
      <ul>
        <li>Violate any applicable laws or regulations</li>
        <li>Infringe on the rights of others</li>
        <li>Upload or distribute viruses or malicious software</li>
        <li>Engage in spamming or other abusive behavior</li>
      </ul>
    </p>

    <h2>4. Intellectual Property</h2>
    <p>All content and materials on our website are protected by intellectual property laws. You may not use, reproduce, or distribute any content without our permission.</p>

    <h2>5. Termination</h2>
    <p>We reserve the right to terminate or suspend your account and access to our services at our sole discretion, without prior notice or liability, for any reason, including if you breach these Terms of Service.</p>

    <h2>6. Limitation of Liability</h2>
    <p>To the fullest extent permitted by law, Pentesters Network shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein.</p>

    <h2>7. Governing Law</h2>
    <p>These Terms of Service are governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.</p>

    <h2>8. Changes to the Terms</h2>
    <p>We reserve the right to modify these Terms of Service at any time. We will notify you of any changes by posting the new terms on our website. Your continued use of the services after any changes constitutes your acceptance of the new terms.</p>

    <h2>9. Contact Us</h2>
    <p>If you have any questions about these Terms of Service, please contact us at [Your Contact Information].</p>
  </div>
);

export default TermsOfService;
