import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NotificationIcon from './NotificationIcon';
import { UserIcon, SignInIcon, RegisterIcon, SignOutIcon } from './StyledComponents';
import logo from '../assets/logo.png';
import notificationService from '../services/notificationService';
import '../styles.css';

const Header = ({ user, onLogout }) => {
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0);
  const [profilePicture, setProfilePicture] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState({});

  useEffect(() => {
    if (user) {
      console.log('User object:', user.id);
      console.log('Header test: ', user);
      notificationService.getNotificationCount().then((count) => {
        setNotificationCount(count);
      });
    }
  }, [user]);

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = (menuName) => {
    setIsSubMenuOpen((prev) => ({
      ...prev,
      [menuName]: !prev[menuName],
    }));
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="header-container">
      <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
        <img className="logo" src={logo} alt="Logo" data-has-notifications={notificationCount > 0} />
        <span style={{ fontSize: '24px', marginLeft: '10px' }}></span>
      </Link>
      <div className="menu-icon" onClick={toggleMenu}>
        ☰
      </div>
      <nav className={`nav-bar ${isMenuOpen ? 'open' : ''}`}>
        <div className='divider'>|</div>
        <Link to="/" onClick={handleLinkClick}>
          Home
        </Link>
        <div className='divider'>|</div>
        <Link to="/forum" onClick={handleLinkClick}>
          Forum
        </Link>
        <div className='divider'>|</div>
        <div className="dropdown" onClick={() => toggleSubMenu('knowledgeBase')}>
          <span>Knowledge Base</span>
          <div className={`dropdown-content ${isSubMenuOpen.knowledgeBase ? 'open' : ''}`}>
            <div className="dropdown-submenu">
              <span onClick={(e) => { e.stopPropagation(); toggleSubMenu('testingStandards'); }}>Testing Standards</span>
              <div className={`dropdown-submenu-content ${isSubMenuOpen.testingStandards ? 'open' : ''}`}>
                <Link to="/web-testing-standards" onClick={handleLinkClick}>Web</Link>
                <Link to="/infrastructure-testing-standards" onClick={handleLinkClick}>Infrastructure</Link>
              </div>
            </div>
            <Link to="/faq" onClick={handleLinkClick}>FAQ</Link>
          </div>
        </div>
        <div className='divider'>|</div>
        <Link to="/contact-us" onClick={handleLinkClick}>Contact Us</Link>
        <div className='divider'>|</div>
        {!user ? (
          <>
            <Link to="/login" onClick={handleLinkClick}>
              <SignInIcon />Login
            </Link>
            <Link to="/register" onClick={handleLinkClick}>
              <RegisterIcon />Register
            </Link>
          </>
        ) : (
          <>
            <Link to="/dashboard" onClick={handleLinkClick}>
              Dashboard
            </Link>
            <NotificationIcon count={notificationCount} />
            <div className='divider'>|</div>
            {user.profilePicture && <img src={`https://ptesters.net/${user.profilePicture}`} alt="Profile" />}
            {user && user.id && (
              <Link className="user-name" to={`/profile/${user.id}`} onClick={handleLinkClick}>
                {user.profilePicture ? (
                  <img className="profile-picture" src={`https://ptesters.net/${user.profilePicture}`} alt="Profile" />
                ) : (
                  <UserIcon />
                )}
                {user.name}
                <div className="dropdown-content">
                  <div className="dropdown-submenu">
                    <span>Name: {user.name}</span>
                    <span>Email: {user.email}</span>
                    <span>Role: {user.role}</span>
                  </div>
                </div>
              </Link>
            )}
            {user.role === 'admin' && <Link to="/admin" onClick={handleLinkClick}>Admin Panel</Link>}
            <button className="logout-button" onClick={() => { handleLogout(); handleLinkClick(); }}>
              <SignOutIcon />
              Logout
            </button>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
